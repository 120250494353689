import { Injectable } from "@angular/core";
import * as data from "../../modules/translation/error-code.json";

import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from "@angular/common/http";
import { Observable, Subject, catchError, concatMap, from, map, switchMap, take, throwError } from "rxjs";
import { ToastService, ToastType } from "../service/toast.service";
import { LoadingSpinnerService } from "../service/loading-spinner.service";
import { TranslationService } from "../service/translation.service";
import { SharingDataService } from "../service/sharing-data.service";
import { environment } from "src/environments/environment";
import { Router } from "@angular/router";
import { AuthAmplifyService } from "../service/auth-amplify.service";
import { DataServiceServiceProfile } from "src/app/modules/alias/services/profile-data-service.service";
import { Auth } from "aws-amplify";

@Injectable()
export class ErrorHandlingInterceptor implements HttpInterceptor {
  errorCodes: any;
  isRefreshing:boolean=false
  groupName:any=''
  errorStatus=[429,403,401,404]
  errorCodeArray:any[]=['1932','1717','1719']
  constructor(
    private toastService: ToastService,
    private loadingSpinnerService: LoadingSpinnerService,
    private translationService: TranslationService,
    private sharingDataService:SharingDataService,
    private authAmplifyService:AuthAmplifyService,
    private profileDataService:DataServiceServiceProfile,
    private route:Router
  ) {
    this.errorCodes = data;
  }

  currentToken:any=localStorage.getItem('Token')
  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      map((res) => {
        return res;
      }),
      catchError((error: HttpErrorResponse) => {
        const isPhraseApi = request.url;
        const phraseUrl = environment.phraseEndPoint
        if (isPhraseApi && isPhraseApi.startsWith(phraseUrl)) {
          return throwError(() => error);
        }
        if (!request.url?.includes('callLocApi') && !this.errorStatus.includes(error.status) && ! this.errorCodeArray.includes(error.error['error-code'])) {
        let errorMsg = "";
        if (error.error instanceof ErrorEvent) {
          errorMsg = `Error: ${error.error.message}`;
        } else {
          this.loadingSpinnerService.hide();
          let errorCode = `${error.error["error-code"]}`;
          const transData = this.translationService.getTranslationData();
          let index = transData.findIndex(
            (v: any) => v.key.name == this.errorCodes[errorCode]
          );
          errorMsg = `${error.error["error-message"]}`;

          this.toastService.show(
            ToastType.Error,
            transData[index] ? transData[index].content: error.error["error-message"],
            "1%",
            "40%"
          );
          switch (errorCode) {
            case "1005":
            case "1205":
            case "1004":
              this.toastService.show(
                ToastType.Error,
                `${transData[index].content.split(".")[0]}`,
                "1%",
                "40%"
              );
              break;
            case "1901":
              this.toastService.show(
                ToastType.Error,
                `Something went wrong`,
                "1%",
                "40%"
              );
             break;
             case "1502":
             this.sharingDataService.setScoreMatchCheck(true);
             break;
             case "1501":
             case "1014" :
             this.route.navigateByUrl('page-not-found')
             break;
          }

          setTimeout(() => {
            this.toastService.hide();
          }, 4000);
        }
        return throwError(() => errorMsg);
      } else {
        if(error.status==403){
          return this.handle403Error(request, next, error);     
        }else if(error.status==401){
          if(error.error["error-code"]=='1014'){
            //for now redirecting to page not found once design is ready will update
            this.loadingSpinnerService.hide()
            this.route.navigateByUrl('page-not-found')
          }
          return this.handle401Error(request, next, error)
        }
        else if(+error.status===404){
          this.loadingSpinnerService.hide()
          this.route.navigateByUrl('page-not-found')
          return throwError(() => error);
        }
        else{
          return throwError(() => error);
        }
      }
      })
    );
  }
  handle401Error(request: HttpRequest<any>, next: HttpHandler, err: any){
    return next.handle(this.addTokenHeader(request, this.currentToken));
  }

  private handle403Error(request: HttpRequest<any>, next: HttpHandler, err: any) {
    // if (!this.isRefreshing) {
    //     this.isRefreshing = true;
        
    //     return from(Auth.currentSession()).pipe
    //     (switchMap(
    //         (res: any) => {
    //             this.isRefreshing = false;
    //             let accessToken = res.getAccessToken()
    //             let jwt = accessToken.getJwtToken();
    //             let jwt_expires_in = accessToken.getExpiration();
    //             let refreshToken = res.getRefreshToken();
    //             let jwtRefreshToken = refreshToken.getToken();
    //             let idToken = res.getIdToken();
    //             let jwtIdToken = idToken.getJwtToken();
    //             let fullData = { "id_token": jwtIdToken, "access_token": jwt, "refresh_token": jwtRefreshToken, "expires_in": jwt_expires_in, "token_type": "Bearer" }
    //             this.authAmplifyService.setUserLoginStatus(true);
    //             this.authAmplifyService.setCurrentUserToken(fullData);
    //             // this.refreshSessionComponent.triggerProfileApi()
    //             const profile = localStorage.getItem('aliasGroupName')
    //             this.groupName = {
    //               groupName: profile,
    //             };
    //             const payload = this.groupName;   
    //             const switchProfileCompleted = new Subject<any>();
    //             let aliasGroupName=localStorage.getItem("aliasGroupName")
    //             if(aliasGroupName){
    //             this.profileDataService.switchProfile(payload).pipe(
    //               take(1)
    //             ).subscribe(

    //               (data:any) => {
    //                 switchProfileCompleted.next(data);
    //                 switchProfileCompleted.complete();
    //                 localStorage.setItem('profileImage',data.profileImageUrl)
    //                 this.sharingDataService.setProfileChanged(true)
    //               },
    //               (error:any) => {
    //                 return throwError(()=> error)
    //               }
    //             );
    //             }else{
    //               return next.handle(this.addTokenHeader(request, jwt));
    //             }
               
    //             return switchProfileCompleted.pipe(
    //               concatMap(() => {
    //                 return next.handle(this.addTokenHeader(request, jwt));
    //               })
    //             );
    //         }),
    //         catchError((err) => {   
    //           // If we get 403 error more than once from the same, log them out
    //             this.isRefreshing = false;
    //             if (err.status === 403) {
    //               localStorage.removeItem("profile")
    //               this.authAmplifyService.logout();
    //             }
    //             // localStorage.clear()    
    //             return throwError(() => err);
    //         })
    //     )
    //     ;

    // } else {
      this.route.navigate(['auth'])            
        //removed the logout code from here because simultaneous calls from locations landing page gave 403 it logged out the user,
        return throwError(() => err);
    // }
}

private addTokenHeader(request: HttpRequest<any>, token: string) {
    return request.clone({
        setHeaders: {
            Authorization: `Bearer ${token}`
        }
    });
}
}