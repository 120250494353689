import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AliasRoutingModule } from './profile-routing.module';
import { AliasListComponent } from './alias-list/alias-list.component';
import { AliasGroupMembersComponent } from './alias-group-members/alias-group-members.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { InviteMemberFormComponent } from './invite-member-form/invite-member-form.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ProfileSwitchComponent } from './profile-switch/profile-switch.component';
import { GroupSectionComponent } from './profile-switch/group-section/group-section.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { TranslationModule } from '../translation/translation.module';
import { FleetsComponent } from './fleet-managment/fleets/fleets.component';
import { FleetCardComponent } from './fleet-managment/fleet-card/fleet-card.component';
import { AddCarComponent } from './fleet-managment/add-car/add-car.component';
import { CommonAlertModalModule } from 'src/app/components/common-alert-modal/common-alert-modal.module';
import { ToastModule } from 'src/app/components/toast/toast.module';
import { CreateEditProfileComponent } from './create-edit-profile/create-edit-profile.component';
import { AccessDeniedComponent } from './access-denied/access-denied.component';
import { ImageCropperModule } from "ngx-image-cropper";

@NgModule({
  declarations: [
    AliasGroupMembersComponent,
    InviteMemberFormComponent,
    AliasListComponent,
    ProfileSwitchComponent,
    GroupSectionComponent,
    FleetsComponent,
    FleetCardComponent,
    AddCarComponent,
    CreateEditProfileComponent,
    AccessDeniedComponent
  ],
  imports: [
    CommonModule,
    AliasRoutingModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    NgxSkeletonLoaderModule,
    TranslationModule,
    CommonAlertModalModule,
    ToastModule,
    ImageCropperModule
  ],
  exports:[
    ProfileSwitchComponent,
    GroupSectionComponent
  ]
})
export class AliasModule { }
