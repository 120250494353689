import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { AlertModalService } from '../../services/modal/alert-modal.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MediaCarouselService } from '../../services/media/media-carousel.service';
import { Subscription, finalize } from 'rxjs';
import { ToastService, ToastType } from "src/app/shared/service/toast.service";
import { TranslationService } from 'src/app/shared/service/translation.service';
import { ASSET_TYPES, AutoSuggestResponse, EMODAL_PRIMARY_ACTIONS, ETOUR_CREATION_STEPS, RecentSearch, TourDetailsUpdateRequest } from '../../interfaces/business-tour-create.interface';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { LocationsHttpService } from '../../services/locations-http.service';
import { CircuitsServicesService } from '../../services/circuits-services.service';
import { LOCATIONS_CONSTANTS } from '../../interfaces/locations-constants';
import { ESEARCH_PAGE_STRING } from '../../interfaces/search-location.interface';
import { SearchComponent } from '../search/search.component';
import { AppConstants } from 'src/app/shared/constants/constants';
import { LoadingSpinnerService } from 'src/app/shared/service/loading-spinner.service';
import { Location } from "@angular/common";
import { TrackuploadComponent } from '../../pages/trackupload/trackupload.component';
import { StoreManagerService } from 'src/app/shared/store-manager/services/store-manager.service';

@Component({
  selector: 'app-create-dealerflow-modal',
  templateUrl: './create-dealerflow-modal.component.html',
  styleUrls: ['./create-dealerflow-modal.component.scss']
})
export class CreateDealerflowModalComponent implements OnInit, OnDestroy {

  @Output() selectedLocationName = new EventEmitter<AutoSuggestResponse>();
  @Output() sendHighlightStep = new EventEmitter<any>();
  @Output() sendSearchClosed = new EventEmitter<any>();
  @Output() sendWaypointDetails = new EventEmitter<any>();
  @Output() callFetchCategories = new EventEmitter<any>();
  @Output() sendLicence = new EventEmitter<any>();
  @Output() sendTourInfo = new EventEmitter<any>();
  @Output() sendStartInfo = new EventEmitter<any>();
  @Output() sendDestinationInfo = new EventEmitter<any>();
  @Output() initiateTourPublish = new EventEmitter<any>();
  @Output() cancelCurrentWaypoint = new EventEmitter<any>();
  @Output() abortDataSendOnClick = new EventEmitter<boolean>();
  @Output() uploadedTourId = new EventEmitter<any>();
  @Output() cancelButtonClicked = new EventEmitter<boolean>();
  @Output() sendEditedData = new EventEmitter<any>();
  @Output() sendEditFlowTrigger = new EventEmitter<any>();
  @Output() sendDeleteCardFlowTrigger = new EventEmitter<any>();

  @Input() disableGenerateButton: boolean = true;
  @Input() disableUpdateBtn: boolean = false;
  // @Input() currentStep:any = ''
  @Input() searchResult: any = null
  @Input() tourPublished: boolean = false;
  @Input() disablePublishButton: boolean = true;
  @Input() nameOfTour: any = ''
  @Input() editedPinIndex: any;
  @Input() editHighlightPin: boolean = false;
  @Input() editedHighlightInfo: any;
  @Input() autoAnnotate:any = true;

  navigateUrl = "/locations";
  navigateMessage = "Back";
  noPropagationCondition: boolean = true;
  tourName: string = "";
  waypointName: any = null;
  waypointDesc: string = '';
  highlightName: string = '';
  highlightDesc: string = '';
  tourDesc: string = "";
  annotationName: string = "";
  @Input() currentStep: string = ETOUR_CREATION_STEPS.SECOND;
  page = "addnewdealer";
  newpage = ESEARCH_PAGE_STRING.ADD_NEW_EMOTION_TOUR;
  placeholderText: string = 'Search';
  selectedLocation: any = null;
  isOpenModal: boolean = true;
  index = 1;
  highlightCount: number = 0;
  wayPointData: any;
  selectedMedia: File[] = [];
  mediaFiles: { originalMedia: any; media: any; copyRightData: any; }[] = [];
  mediaFilesOverlay: any[] = [];
  showOverlayFlag: boolean = false;
  hideFormData: boolean = false;
  // toggleSelected: boolean = true;
  destinationData: any;
  startLocationButtonClickedFlag: boolean = false;
  translationData: any;
  addMoreDetailsFlag: boolean = false;
  onFocusEnlargeFont: boolean = true;
  wayPointObj: any = [];
  editActive: any;
  subscribeMediaData: any;
  helperMessages = LOCATIONS_CONSTANTS.HELPER_MSGS;
  @ViewChild('tourForm') tourForm: any;
  @ViewChild('searchComp') searchComp: SearchComponent | undefined;
  @ViewChild('gpxButton') gpxButton: any;
  @ViewChild('trackUploadComponent') trackUploadComponent: TrackuploadComponent | undefined
  topValue: number = 100;
  leftValue: number = 121;
  showCopyrightDialogBoxWithSavedInfo: {showModal: boolean, saveImgWithCopyright: boolean} = {showModal: false, saveImgWithCopyright: false};

  addRouteForm = this._formBuilder.group({
    annotationName: [''],
    annotationDesc: [''],
    annotationImg: ['']
  })

  // Post Tour Publish variable declarations go here
  isMenuDropdownVisible: boolean = false;
  @Input() public displayPublishedDetails: boolean = false;
  @Input() public tourInfoPostPublish: {
    guid: string | undefined, createdDate: string | undefined
    nickName: string | undefined
    tourName: string | undefined
    tourDescription: string | undefined
    trackVersions: string[] | []
  } | any;
  @Input() public coverImg: string = '';
  public showReadMore: boolean = true;
  subscription: Subscription[] = [];
  public isDeleteSelected: boolean = false;
  public tourPublishedView: boolean = false;
  showModal: any;
  uploadHeading: string = 'BP_LM_UPLOAD_TOUR'
  editedMediaFiles: any;
  getEditStatus: any;
  public languageSelected: string = 'en-GB';
  public isRoundTrip: boolean = false;
  public hovered: boolean = false;
  markFieldsAsRequired: boolean = false;
  public viewSelectedCardDetails: boolean = false;
  public isAudioUploadVisible: boolean = false;
  showSearchComponent: boolean = false;
  currentUserProfile: string = '';
  // Feature flags
  public isRoundTripAvailable: boolean = false;
  public enableEditPublishedTour: boolean = false;
  public enableAudioJsonUpload: boolean = false;
  buttonNames: any = AppConstants.COMMON_BUTTON_LABELS;
  commonLabels: any = AppConstants.COMMON_LABELS;
  editAfterPublishActive:boolean = false;
  locationButtonNames: any = LOCATIONS_CONSTANTS.BUTTON_NAMES;
  allSupportedLangCodes: any[];
  public displayGpxMenu: boolean = false;
  public availableTrackVersions: string[] = [];
  public displayDownloadIcon: boolean = false;
  public getGpxButtonPos: { left: any; top: any; bottom: any; right: any; } | undefined;
  isOffroadTourFlow: boolean = true;
  trackType: string = 'BP_LM_OFFROAD_LBL';
  asset_type: string = ASSET_TYPES.TRACK_COVER;
  gpxFilesUpload: File[] = [];

  constructor(private alertModalService: AlertModalService,
    private router: Router,
    private _actRoute: ActivatedRoute,
    private _location: Location,
    private mediaCarousalService: MediaCarouselService,
    private toastService: ToastService,
    private translationService: TranslationService,
    private _locationService: LocationsHttpService,
    private mediaService: CircuitsServicesService,
    private _formBuilder: UntypedFormBuilder,
    private _circuitService: CircuitsServicesService,
    private _spinnerService: LoadingSpinnerService,
  private _storeManagerService: StoreManagerService) {
      this.currentUserProfile = localStorage.getItem('profileName') ?? ''
    if (this._actRoute.snapshot.routeConfig?.path?.includes(':trackType/:guid')) {
      this.tourPublishedView = true;
    }
    this.allSupportedLangCodes = this.translationService.getSupportedLanguageByCode();
    this.uploadMediaEmitter()
  }

  ngOnInit(): void {
    this.buttonNames = AppConstants.COMMON_BUTTON_LABELS;
    const lang = this._storeManagerService.getUserSelectedLanguageFromStore();
this.availableTrackVersions = this.tourInfoPostPublish?.trackVersions ?? this.availableTrackVersions;
    
this.languageSelected = lang ?? this.allSupportedLangCodes.find(lang => lang.includes('en')) ?? 'en-GB';
    this.getEditStatus = this.mediaService.shareEditActive.subscribe((data:any) => {
      this.editActive = data;
    })
    this.isOffroadTourFlow = this._actRoute.snapshot.params['trackType'] === 'offroad-trail';
    this.subscription.push(this.alertModalService.onAction.subscribe((data: any) => {
      if (data?.page) {
        this.handleModalSubscriptions(data);
        this.alertModalService.onAction.next(null); 
      }
    }));

    this.subscription.push(this.mediaCarousalService.CancelCurrentStep.subscribe((data: string) => {
      if (data) {
        this.currentStep = data;
      }
    }));

    this.subscription.push(this.mediaCarousalService.viewSelectedCardDetails.subscribe((data: boolean) => {
      if (data) {
        this.changeModalView(data);
      } else if (this.viewSelectedCardDetails) {
        this.hideSelectedCardDetails();
      }
    }));

    this.subscription.push(this.alertModalService.onAction.subscribe((data: any) => {
      if (data && data['action'] === true) {
        this.handleCancelActionFromAlert(data);
        this.handleAddAnnotationActionFromAlert(data);
      }
    }));

    this.subscription.push(this.mediaCarousalService.highlightCount.subscribe((data: number) => {
      if (data >= 0) {
        this.highlightCount = data;
      }
    }));

    this.subscription.push(this.mediaCarousalService.hideContentOnLoadersPage.subscribe((data: boolean) => {
      this.hideFormData = data;
    }));

    // Issue: BETRACKPRO-18826: Even if the popup was delete popup was closed, options were still showing highlighted
    this.subscription.push(this.alertModalService.onClose.subscribe((isClosed: any) => {
      if (!isClosed) {
        this.isDeleteSelected = false;
      }
    }))


    this.translationService.getTranslationDataAsObservable().subscribe((translationData: any) => {
      this.translationData = translationData;
      this.isRoundTripAvailable = this.translationService.featureFlags.locations.enableRoundTrip;
      this.enableEditPublishedTour = this.translationService.featureFlags.locations.enableEditPublishedTour;
    });

    this.addRouteForm.get('annotationName')?.valueChanges.subscribe((val: any) => {
      if (this.addMoreDetailsFlag) {
        const descValidator = this.addRouteForm.controls['annotationDesc'];
        if (val && val !== this.selectedLocation?.name) {
          descValidator?.setValidators([Validators.required]);
          this.markFieldsAsRequired = true
        } else {
          descValidator?.setValidators(null);
          this.markFieldsAsRequired = false;
        }
        descValidator?.updateValueAndValidity();
      }
    });

    this.addRouteForm.get('annotationDesc')?.valueChanges.subscribe((val: any) => {
      if (this.addMoreDetailsFlag && (val || (!val && (this.mediaFiles?.length)))) {
        this.disableUpdateBtn = false;
        this.markFieldsAsRequired = true;
        if (this.editActive && !val) {
          this.disableUpdateBtn = true;
          this.markFieldsAsRequired = true;
        }
      } else if (this.editActive && !val) {
        this.disableUpdateBtn = true;
        this.markFieldsAsRequired = true;
      } else {
        this.markFieldsAsRequired = false;
      }
    });
    
  }
  
  public changeModalView(viewDetails: boolean): void {
    this.viewSelectedCardDetails = viewDetails;
    if (viewDetails) {
      this.selectedLocation = this.mediaCarousalService.insiderSptDetails;
    } else {
      this.mediaCarousalService.insiderSptDetails = null;
    }
    console.log('selectedLocation :>> ', this.selectedLocation);
  }

  ngOnChanges(changes: SimpleChanges) {
    if(this.currentStep === ETOUR_CREATION_STEPS.EDIT_AFTER_PUBLISH && !this.disablePublishButton){

      this.mediaService.clearMediaValue();
    }
    if (this.currentStep === 'TOUR_PUBLISHED') {
      this.goBackToPublishStage();
    }
    if (this.currentStep === ETOUR_CREATION_STEPS.DESTINATION_ADDED || this.currentStep === ETOUR_CREATION_STEPS.WAY_POINT_ADDED) {
      this.trackType = this.isOffroadTourFlow ? 'BP_LM_OFFROAD_LBL': 'BP_LM_EMOTION_TOUR_LBL';
    }
    if (this.editedHighlightInfo?.id && this.editActive || (this.currentStep === ETOUR_CREATION_STEPS.EDIT_START || this.currentStep === ETOUR_CREATION_STEPS.EDIT_DESTINATION)) {
      this.addMoreDetailsOnClk(this.editActive);
      let mediaList = this.mediaService.getHighlightMediaValue();
      mediaList.forEach((el: any) => {
        if (el.highlightName === this.selectedLocation.title) {
          this.mediaFiles = el.media;
        }
      });
      if (this.mediaFiles?.length > 0) {
        this.mediaService.sendMediaValueForAnnotations(this.mediaFiles);
      }
    }
    this.getTrackVersions(changes);
    if (this._actRoute.snapshot.routeConfig?.path?.includes(':trackType/:guid/edit') && this.currentStep === ETOUR_CREATION_STEPS.EDIT_AFTER_PUBLISH) {
      this.changeStepToEdit();
      this.populateTourDetailsForEdit(changes)
    }
    if (changes.searchResult?.currentValue) {
      this.populateAnnotationMediaForEdit(changes.searchResult); // Handle annotation media post publish
      // Shreya to check with Minnu for impacts.
      // Issue: Each time for step change it comes to this block and set's selected location to undefined
      this.selectedLocation = {
        name: this.searchResult?.name ? this.searchResult.name : this.searchResult,
        title: this.searchResult?.title ? this.searchResult.title : this.searchResult?.name,
        description: this.searchResult?.description ? this.searchResult.description : ''

      };
      if (this.currentStep === ETOUR_CREATION_STEPS.ADD_WAY_POINT_DETAILS &&
        (this.editAfterPublishActive || this.tourInfoPostPublish)) {
        //To automatically open the details modal
        this.asset_type = ASSET_TYPES.ANNOTATION;
        if (this.editActive) {
          this.addRouteForm.patchValue({
            annotationName: this.editedHighlightInfo?.properties?.name ?? this.selectedLocation.name.substring(0, 50),
            annotationDesc: this.editedHighlightInfo?.properties?.description ?? this.selectedLocation.description
          });
        }
        this.addMoreDetailsFlag = true;
        this.markFieldsAsRequired = true;
      }
    }
    this.getGpxButtonPosition()
  }

  private populateTourDetailsForEdit(changes: SimpleChanges) {
    if (changes.tourInfoPostPublish) {
      this.tourName = this.tourInfoPostPublish?.tourName ?? this.tourName;
      this.tourDesc = this.tourInfoPostPublish?.tourDescription ?? this.tourDesc;
    }
  }

  private getTrackVersions(changes: SimpleChanges): void {
    if (changes.tourInfoPostPublish) {
      this.availableTrackVersions = this.tourInfoPostPublish?.trackVersions ?? this.availableTrackVersions;
    }
  }

  /**
   * @description Called to populate insider spot details for AMG annotations.
   * If the current value ID and previous value
   * Doing this because mediaFiles are getting populated with duplicate values as 
   * this method is called multiple times.
   * @param value 
   */
  public populateAnnotationMediaForEdit(value: any): void {
    if (value.currentValue?.id !== value.previousValue?.id || !this.mediaFiles?.length) {
      if (this.searchResult?.media_details && this.searchResult?.media_details?.length > 0) {
        this.searchResult.media_details.forEach((element: any) => {
          // this.mediaService.sendMediaValueForCover(element); 
          if (element.id) {
            this.mediaFiles.push(element);
          }
        });
      }
    }
  }

  public searchCancelledEmiiter(isCancelled: boolean): void {
    if (isCancelled) {
      this.selectedLocation = { name: '' }
      this.searchResult = ''
      !this.editActive ? this.goBackToSearch(this.currentStep === 'ADD_WAY_POINT_DETAILS' ? 'waypoint' : 'start_or_dest'): this.goToSearchForNewPoint();
    }
  }

  public goBackToSearch(data: any): void {
    this.resetFormData();
    if (data === 'start_or_dest') {
      if (this.startLocationButtonClickedFlag && (this.currentStep === ETOUR_CREATION_STEPS.EDIT_DESTINATION || this.currentStep === ETOUR_CREATION_STEPS.THIRD)) {
        this.currentStep = ETOUR_CREATION_STEPS.THIRD;
        this.sendSearchClosed.emit('destination');
      } else {
        this.currentStep = ETOUR_CREATION_STEPS.THIRD;
        this.sendSearchClosed.emit('start');
      }
    } else {
      // Issue: When x was clicked from Search, on Add Details for screen, prev data was not cleared
      if ('waypoint' === data && ETOUR_CREATION_STEPS.ADD_WAY_POINT_DETAILS === this.currentStep) {
        this.highlightDesc = '';
        this.resetUploadMedia();
        this.resetFormData();
      }
      this.currentStep = ETOUR_CREATION_STEPS.ADD_WAY_POINT;
      let waypointInfo = data + this.index;
      this.sendSearchClosed.emit(waypointInfo);
    }

    this.mediaCarousalService.showPlusIconOnMapHover.next(true);
  }

 /**
  * @description Called when we click on Back button while Editing an insider point is active
  */
  public goToSearchForNewPoint(): void {
    if (this.currentStep !== ETOUR_CREATION_STEPS.EDIT_DESTINATION && this.currentStep !== ETOUR_CREATION_STEPS.EDIT_START) {
      this.currentStep = ETOUR_CREATION_STEPS.ADD_WAY_POINT;
    }
    this.editHighlightPin = this.addMoreDetailsFlag = false;
    this.mediaCarousalService.showPlusIconOnMapHover.next(true);
    if  (!this.addMoreDetailsFlag && this.editedHighlightInfo?.id) {
      this.editedHighlightInfo = <any>null;
      this.editedPinIndex = false;
      this.resetUploadMedia();
    this.resetFormData();
     }
    this.sendEditedData.emit(this.editedPinIndex);
  }

  /**
   * @description called when we make a selection from search dropdown
   * @param event 
   */
  emitChangeInput(event: AutoSuggestResponse) {
    if (event.constructor === Object && Object.keys(event).length !== 0) {
      if (this.currentStep === ETOUR_CREATION_STEPS.ADD_WAY_POINT || this.currentStep === ETOUR_CREATION_STEPS.ADD_WAY_POINT_DETAILS || this.currentStep === ETOUR_CREATION_STEPS.DESTINATION_ADDED) {
        this.currentStep = ETOUR_CREATION_STEPS.ADD_WAY_POINT_DETAILS;
      } else if (this.currentStep === ETOUR_CREATION_STEPS.EDITING_DESTINATION || this.currentStep === ETOUR_CREATION_STEPS.EDITING_START) {
        // Keep same step as if
        this.currentStep = this.currentStep === ETOUR_CREATION_STEPS.EDITING_DESTINATION ? ETOUR_CREATION_STEPS.EDIT_DESTINATION: ETOUR_CREATION_STEPS.EDIT_START;
      } else if (!this.editActive) {
        this.currentStep = ETOUR_CREATION_STEPS.THIRD;
      }
      this.selectedLocation = {
        name: event.address?.label ?? event.title,
        title: event.title,
        position: event.position
      }
      // If the location is already present in the list, do not set it again
      if (!(this.mediaCarousalService.recentSearchHistory.some((search: RecentSearch) => this.selectedLocation.title === search.title &&
        search.position.lng === this.selectedLocation.position.lng && search.position?.lat === this.selectedLocation.position?.lat))) {
        this.mediaCarousalService.recentSearchHistory = this.selectedLocation;
      }
      this.selectedLocationName.emit(event);
    }
  }

  /**
   * @description called when we click on Save and Publish for Start/Destination Point. Also once when start point is edited
   */
  public saveStartOrDestinationOnClk(): void {
    if (this.editActive) {
      this.currentStep = ETOUR_CREATION_STEPS.WAY_POINT_ADDED;
      let startData = {
        name: this.selectedLocation.name,
      title: this.addRouteForm.controls['annotationName'].value ? this.addRouteForm.controls['annotationName'].value: this.selectedLocation.name,
      description: this.addRouteForm.controls['annotationDesc'].value,
      media_details: this.mediaFiles,
      }
      this.sendHighlightStep.emit(this.currentStep);
      this.sendWaypointDetails.emit(startData);
      let mediaArray = {
        highlightName: this.selectedLocation.name,
        media: this.mediaFiles
      }
      this.mediaService.setHighlightMediaValue(mediaArray);
      this.editActive = false;
    } else {
      if (this.waypointName === '' || this.waypointName === null) {
        this.waypointName = this.selectedLocation;
      }
      if (this.startLocationButtonClickedFlag) {
        this.currentStep = ETOUR_CREATION_STEPS.DESTINATION_ADDED;
        let destinationData = {
          name: this.waypointName.name,
          title: this.selectedLocation?.title,
          description: this.waypointDesc,
          media_details: []
        }
        if (this.wayPointObj?.length > 0) { this.currentStep = ETOUR_CREATION_STEPS.WAY_POINT_ADDED; }
        this.sendDestinationInfo.emit(destinationData);
        this.mediaCarousalService.showPlusIconOnMapHover.next(false);
        this.disableGenerateButton = false;
      } else {
        this.currentStep = ETOUR_CREATION_STEPS.START_ADDED;
        let startData = {
          name: this.waypointName?.name,
          title: this.selectedLocation?.title,
          description: this.waypointDesc,
          media_details: []
        }
        this.sendStartInfo.emit(startData);
        this.startLocationButtonClickedFlag = true;
        this.mediaCarousalService.startLocationButtonClicked.next(true);
        this.mediaCarousalService.showPlusIconOnMapHover.next(true);
      }
      this.sendHighlightStep.emit(this.currentStep);
      this.waypointDesc = this.waypointName = '';
    }
    this.selectedLocation = {name: ''}
    this.searchResult = '';
    this.resetUploadMedia();
    this.resetFormData();
    this.addMoreDetailsFlag = false;
  }

  /**
   * @description called when Save Route is clicked
   */
  public sendWaypointData(): void {
    if (this.highlightName === '' || this.highlightName === null) {
      this.highlightName = this.selectedLocation;
    }
    if(this.editAfterPublishActive){
      this.currentStep = ETOUR_CREATION_STEPS.EDIT_AFTER_PUBLISH;
    } else{
      this.currentStep = ETOUR_CREATION_STEPS.WAY_POINT_ADDED;
    }
    this.showCopyrightDialog();
    this.wayPointData = {
      name: this.selectedLocation.name,
      title: this.addRouteForm.controls['annotationName'].value ? this.addRouteForm.controls['annotationName'].value: this.selectedLocation.name,
      description: this.addRouteForm.controls['annotationDesc'].value,
      media_details: this.mediaFiles,
    }
    if (this.editHighlightPin) { this.wayPointData.annotationId = this.editedHighlightInfo?.id }
    this.sendHighlightStep.emit(this.currentStep);
    this.sendWaypointDetails.emit(this.wayPointData);
    let mediaArray = {
      highlightName: this.selectedLocation.name,
      media: this.mediaFiles
    }
    this.mediaService.setHighlightMediaValue(mediaArray);
    this.wayPointObj.push(this.wayPointData);
    this.mediaCarousalService.waypointAdd.next(true);
    this.mediaCarousalService.CancelCurrentStep.next(this.currentStep);
    this.index = this.index + 1;
    this.highlightDesc = this.highlightName = '';
    this.resetUploadMedia();
    this.addMoreDetailsFlag = false;
    this.resetFormData();
    if (this.currentStep !== ETOUR_CREATION_STEPS.WAY_POINT_ADDED) {
      this.mediaCarousalService.showPlusIconOnMapHover.next(true);
    }
    this.selectedLocation = {name: ''}
    this.searchResult = ''
    if (this.editActive) {
      this.highlightName = this.selectedLocation;
      this.mediaService.setEditActiveStatus(false);
      this.editHighlightPin = false;
      this.editedHighlightInfo = null;
      this.highlightDesc = '';
      this.mediaFiles = [];
      this.selectedMedia = [];
      this.selectedLocation = ''
    }
  }

  resetUploadMedia() {
    this.mediaFiles = [];
    this.mediaFilesOverlay = [];
    this.selectedMedia = [];
    this.showOverlayFlag = false;
    this.mediaService.clearMediaValue();
    this.mediaCarousalService.resetMediaWithCopyRightData();
    this.asset_type = ASSET_TYPES.TRACK_COVER
  }

  /**
   * @description Called upon Generate button click, makes an API call to fetch categories for the tour.
   * @returns void
   */
  public fetchCategories(): void {
    this.callFetchCategories.emit();
    let tourInfo = {
      tourName: this.tourName,
      tourDesc: this.tourDesc
    }
    this.sendTourInfo.emit(tourInfo);
  }

  publishTour(tourForm: any) {
    if(this.editAfterPublishActive){
      this.initiateTourPublish.emit(tourForm);
    }else{
      this.mediaService.setSendTourToFleet(true);
      this.initiateTourPublish.emit(tourForm);
      this.currentStep = ETOUR_CREATION_STEPS.TOUR_PUBLISHED;
      this.mediaCarousalService.CancelCurrentStep.next(this.currentStep);
    }
  }

  /**
   * @description called when we click on cancel, back or add start/destination/insider point
   */
  public resetFormData(): void {
    this.addRouteForm.reset();
    this.addRouteForm.clearValidators();
    this.markFieldsAsRequired = false;
  }

  /**
   * @description called when we click on Add details button
   */
  public addMoreDetailsOnClk(isEditActive: boolean): void {
    if (!isEditActive) {
      this.resetFormData();
      this.addRouteForm.patchValue({
        annotationName: '',
        annotationDesc: ''
      });
    } else {
      this.addRouteForm.patchValue({
        annotationName: this.editedHighlightInfo?.properties?.name ?? '',
        annotationDesc: this.editedHighlightInfo?.properties?.description ?? ''
      });
      this.markFieldsAsRequired = true;
    }
    this.addMoreDetailsFlag = true;
    this.asset_type = ASSET_TYPES.ANNOTATION;
  }

  textareaFocusIn() {
    return true;
  }

  public handleStepwiseCancelFlow(): void {
    const cancelDialogTitle = this.isOffroadTourFlow ? LOCATIONS_CONSTANTS.ALERT_POPUP_TITLE.CANCEL_OFFROAD_TRACK_TITLE : LOCATIONS_CONSTANTS.ALERT_POPUP_TITLE.CANCEL_EMOTION_TOUR_TITLE;
    const msg = this.isOffroadTourFlow ? LOCATIONS_CONSTANTS.CONFIRMATION_POPUP_MSGS.DISCARD_OFFROAD_TRACK_MSG : LOCATIONS_CONSTANTS.CONFIRMATION_POPUP_MSGS.DISCARD_EMOTION_TOUR_MSG;
    switch (this.currentStep) {
      case ETOUR_CREATION_STEPS.SECOND:
        if (!this.startLocationButtonClickedFlag) { this.router.navigateByUrl(this.navigateUrl); }
        else {
          this.showDiscardCurrentStepModal(cancelDialogTitle, msg);
        }
        break;
      case ETOUR_CREATION_STEPS.THIRD:
      case ETOUR_CREATION_STEPS.START_ADDED:
      case ETOUR_CREATION_STEPS.START_ADDED:
      case ETOUR_CREATION_STEPS.DESTINATION_ADDED:
      case ETOUR_CREATION_STEPS.WAY_POINT_ADDED:
        this.showDiscardCurrentStepModal(cancelDialogTitle, msg);
        break;
      case ETOUR_CREATION_STEPS.ADD_WAY_POINT:
        // Set Current Step to way point added and take to generate tour screen
        if(this.editAfterPublishActive){
          this.currentStep = 'EDIT_AFTER_PUBLISH';
          this.mediaCarousalService.CancelCurrentStep.next("EDIT_AFTER_PUBLISH");
          this.tourDesc = this.tourInfoPostPublish?.tourDescription?this.tourInfoPostPublish?.tourDescription:'';
          this.tourName = this.tourInfoPostPublish?.tourName?this.tourInfoPostPublish?.tourName:'';
          this.selectedLocation = '';
          this.editActive = false;
          break;
        } else {
          if (!this.editActive) {
            this.mediaCarousalService.showPlusIconOnMapHover.next(false);
            this.abortDataSendOnClick.emit(true);
            this.hideSelectedCardDetails();
            this.editActive = false;
              this.currentStep = ETOUR_CREATION_STEPS.WAY_POINT_ADDED;
              this.mediaCarousalService.CancelCurrentStep.next(this.currentStep);
              this.highlightDesc  = this.highlightName = ''; 
              this.sendHighlightStep.emit(this.currentStep);
          } else {
            this.showDiscardCurrentStepModal(LOCATIONS_CONSTANTS.ALERT_POPUP_TITLE.CANCEL_EDITING_CARD_DTLS,LOCATIONS_CONSTANTS.CONFIRMATION_POPUP_MSGS.UNSAVED_CHANGES_WARNING);
          }
        }        
        break;
      case ETOUR_CREATION_STEPS.ADD_WAY_POINT_DETAILS:
          this.showDiscardCurrentStepModal(!this.editActive ? LOCATIONS_CONSTANTS.ALERT_POPUP_TITLE.CONFIRM_CANCEL: LOCATIONS_CONSTANTS.ALERT_POPUP_TITLE.CANCEL_EDITING_CARD_DTLS, !this.editActive ? LOCATIONS_CONSTANTS.CONFIRMATION_POPUP_MSGS.DISCARD_INSIDER_SPOT_MSG : LOCATIONS_CONSTANTS.CONFIRMATION_POPUP_MSGS.DISCARD_EDIT_INSIDER_SPOT_MSG);          
          break;        
      case ETOUR_CREATION_STEPS.EDIT_START:
      case ETOUR_CREATION_STEPS.EDITING_DESTINATION:
      case ETOUR_CREATION_STEPS.EDIT_DESTINATION:
      case ETOUR_CREATION_STEPS.EDITING_START:
        this.showDiscardCurrentStepModal(LOCATIONS_CONSTANTS.ALERT_POPUP_TITLE.CANCEL_EDITING_CARD_DTLS, LOCATIONS_CONSTANTS.CONFIRMATION_POPUP_MSGS.UNSAVED_CHANGES_WARNING);
        break;
      case ETOUR_CREATION_STEPS.EDIT_AFTER_PUBLISH:
        this.showDiscardCurrentStepModal(cancelDialogTitle, msg);
          break;
    }
  }

  public takeToEditPostPublishStage(): void {
    this.mediaCarousalService.CancelCurrentStep.next('EDIT_AFTER_PUBLISH_CANCELLED');
    this.goBackToPublishStage();
    this.tourDesc = this.tourInfoPostPublish?.tourDescription ? this.tourInfoPostPublish?.tourDescription : '';
    this.tourName = this.tourInfoPostPublish?.tourName ? this.tourInfoPostPublish?.tourName : '';
  }

  public goBackToLocations(): void {
    this.router.navigateByUrl(this.navigateUrl);
  }

  /**
   * @description called when we click Back to Locations
   */
  public showDiscardCurrentStepModal(title: string, msg: string): void {
      this.alertModalService.openModal.next(true);
      let modalData: any = {
        title: title,
        message: msg,
        cancelBtnTitle: AppConstants.COMMON_BUTTON_LABELS.CLOSE,
        actionBtnTitle: AppConstants.COMMON_BUTTON_LABELS.DISCARD,
        flag: !this.editActive ? EMODAL_PRIMARY_ACTIONS.CANCEL_INSIDER_SPOT: EMODAL_PRIMARY_ACTIONS.DISABLE_EDIT
      }
      this.alertModalService.modalData.next(modalData);
  }

  /**
   * @description called when kebab icon is clicked, displays/hides the drop down menu
   * @param event of type Event
   */
  public toggleDropdownMenuOptions(action: string, event?: Event): void {
    if ('show' === action) {
      this.displayGpxMenu = false;
      this.isMenuDropdownVisible = !this.isMenuDropdownVisible;
    } else if ('hide' === action) {
      this.isMenuDropdownVisible = false;
    }
    if (event) { event.stopPropagation(); }
  }

  private getGpxButtonPosition(): void {
    if (this.gpxButton) {
      let rect = this.gpxButton.nativeElement.getBoundingClientRect();
      this.topValue = rect.top;
      this.leftValue = rect.left;
      console.log('rect :>> ', rect);
      this.getGpxButtonPos = {
        left: rect.left,
        top: rect.top,
        bottom: rect.bottom,
        right: rect.right
      }
    }
  }

  /**
   * @description called when View Public Version button is clicked
   */
  public viewPublicVersion() {
    const emotionTourData = JSON.stringify({
      name: this.tourInfoPostPublish?.tourName ? this.tourInfoPostPublish.tourName : this.tourName,
      guid: this.tourInfoPostPublish?.guid,
    });
    this.tourName = (this.tourInfoPostPublish?.tourName ? this.tourInfoPostPublish.tourName : this.tourName)
      .replace(' ', '-')
      .replace(/\s+/g, '-')
      .replace(/[^\w\s]/gi, '-');
    const url = `${environment.routeBaseURL}/${this.languageSelected}/explore/emotion-tour/${this.tourName}-${this.tourInfoPostPublish?.guid}`;
    const newWindow: any = window.open(url, "_blank");

    // Access it using its variable
    if (newWindow) {
      newWindow.emotionTourData = emotionTourData;
    }
  }

  /**
   * @description called when download gpx button is clicked
   */
  public downloadBusinessTourDetails(trackVersion?: string): void {
    const tourNameForSave = this.tourInfoPostPublish?.tourName ?? ''
    this._spinnerService.show();
    this._locationService
      .downloadTrackDetails(this.tourInfoPostPublish?.guid, trackVersion)
      .pipe(finalize(() => {
        this._spinnerService.hide();
        this.displayGpxMenu = false;
      }))
      .subscribe((data: any) => {
        const contentDisposition = data.headers.get('content-disposition');
        let fileName = contentDisposition ? contentDisposition.split('filename=')[1].split(';')[0] : tourNameForSave;
        if (!fileName.endsWith('.zip')) {
          fileName += '.zip';
        }
        const blob = new Blob([data.body], {
          type: 'application/zip',
        });
        const url = window.URL.createObjectURL(blob);
        const anchor = document.createElement("a");
        anchor.download = fileName;
        anchor.href = url;
        anchor.click();
      });
  }

  /**
   * @description display a confirmation popup to user
   */
  public showConfirmDeleteDialog(): void {
    this.isDeleteSelected = true;
    this.alertModalService.openModal.next(true);
    const alertModalDetails = {
      title: this.isOffroadTourFlow ? LOCATIONS_CONSTANTS.ALERT_POPUP_TITLE.DELETE_OFFROAD_TITLE: LOCATIONS_CONSTANTS.ALERT_POPUP_TITLE.DELETE_ET_TITLE,
      message: this.isOffroadTourFlow ? LOCATIONS_CONSTANTS.CONFIRMATION_POPUP_MSGS.DELETE_OFFROAD_TRACK_MSG :LOCATIONS_CONSTANTS.CONFIRMATION_POPUP_MSGS.DELETE_EMOTION_TOUR_MSG,
      flag: EMODAL_PRIMARY_ACTIONS.DELETE_BUSINESS_TOUR,
      cancelBtnTitle: AppConstants.COMMON_BUTTON_LABELS.CANCEL,
      actionBtnTitle: AppConstants.COMMON_BUTTON_LABELS.DELETE
    }
    this.alertModalService.modalData.next(alertModalDetails);
  }

  /**
   * @description calls a service that deletes current busisness tour
   * clean up handled in finalize - called when Observable completes or errors
   */
  public deleteBusinessTourOnConfirm(): void {
    this.isDeleteSelected = false;
    if (this.tourInfoPostPublish?.guid) {
      this._spinnerService.show();
      this._locationService
        .deleteTrack(this.tourInfoPostPublish.guid)
        .pipe(finalize(() => {
          setTimeout(() => {
            this.toastService.hide();
          }, AppConstants.MIN_TIME_TOASTER);
          this.alertModalService.onAction.next(null);
          this.isMenuDropdownVisible = false;
        }))
        .subscribe({
          next: () => {
            this._spinnerService.hide();
            const msg = this.isOffroadTourFlow ? LOCATIONS_CONSTANTS.TOAST_SUCCESS_MESSAGES.OFFROAD_DELETE_SUCCESS_MSG : LOCATIONS_CONSTANTS.TOAST_SUCCESS_MESSAGES.EMOTION_TOUR_DELETE_SUCCESS_MSG;            
            this.toastService.show(ToastType.Success, msg, '8%', '45%');
            setTimeout(() => {
              this.router.navigateByUrl('/locations')
            }, AppConstants.MIN_TIME_TOASTER);
          },
          error: (error: any) => {
            this._spinnerService.hide();
            const errorMsg = this._circuitService.getToastErrMessagesBasedOnCode(error);
            this.toastService.show(ToastType.Error, errorMsg, '1%', '40%');
          },
        });
    }
  }

  /**
   * @description This function is common for both emotion tour and offroad
   * Called when we upload a Gpx file
   * @param file 
   */
  public startTrackUploadOnFileSelect(file: any) {
    
    if (file) {
      this.gpxFilesUpload.push(file);
      if (this.isOffroadTourFlow) {
        this.showIncludeFloraFaunaDetails(LOCATIONS_CONSTANTS.ALERT_POPUP_TITLE.FLORA_FAUNA_TITLE, 'BP_LM_FLORA_FAUNA_MSG');
      } else {
        this.initateUploadOnConfirm();
      }
    }
  }

  /**
   * @description called when we click Back to Locations
   */
  public showIncludeFloraFaunaDetails(title: string, msg: string): void {
    this.alertModalService.openModal.next(true);
    let modalData: any = {
      title: title,
      message: msg,
      cancelBtnTitle: AppConstants.COMMON_BUTTON_LABELS.CLOSE,
      actionBtnTitle: AppConstants.COMMON_BUTTON_LABELS.CONTINUE,
      flag: EMODAL_PRIMARY_ACTIONS.INCLUDE_FLORA_FAUNA,
      showCheckbox: true
    }
    this.alertModalService.modalData.next(modalData);
}

  public closeModal(event: any): void {
    if (!event.showModal) {
      this.showModal = false;
    }
    if (event.gpxuploaded) {
      this.tourName = event.uploadedName;
      this.uploadedTourId.emit(event)
    }
  }

  public handleCancelActionFromAlert(data: any) {
    if (data['page'] === 'cancel_current_step') {
      this.mediaService.setEditActiveStatus(false);
      if(this.editAfterPublishActive){ // Added this condition to make sure current step doesn't change from edit after publish after removing a waypoint
        this.currentStep = ETOUR_CREATION_STEPS.EDIT_AFTER_PUBLISH;
      } else{
        this.currentStep = ETOUR_CREATION_STEPS.WAY_POINT_ADDED;
      }
      this.editHighlightPin = false;
      this.editedHighlightInfo = null;
      this.highlightDesc = '';
      this.mediaFiles = [];
      this.selectedLocation = '';
      this.addMoreDetailsFlag = false;
      this.sendHighlightStep.emit(this.currentStep);
      this.mediaCarousalService.CancelCurrentStep.next(this.currentStep);
    }
  }

  public handleAddAnnotationActionFromAlert(data: any): void {
    if (data['page'] === 'OFFROAD_ADD_FLORA_FAUNA') {
      this.initateUploadOnConfirm(data);
    }
  }

  public initateUploadOnConfirm(data?: any): void {
    const auto_annotate =  data?.isChecked ?? false
    this.showModal = true;
    if (this.trackUploadComponent) {
      this.trackUploadComponent.fileChange(this.gpxFilesUpload[this.gpxFilesUpload.length - 1], auto_annotate);
    }
  }

  /**
   * @description Called when we click on back button while editing card
   */
  public hideSelectedCardDetails(): void {
    this.viewSelectedCardDetails = false;
    this.mediaCarousalService.viewSelectedCardDetails = false;
    this.mediaCarousalService.insiderSptDetails = null;
    this.selectedLocation = { name: '', title: '', description: '' };
    this.searchResult = ''
  }

  /**
   * @description called when x is clicked from image, to remove the specific image. 
   * If there are any image overlay while the visible images become < 3, 
   * move 1st image from overlay and display it to user
   * @param imageIndex
   */
  public removeImage(imageIndex: number): void {
    this.mediaFiles?.splice(imageIndex, 1);
    // Issue: Image was not getting removed from FileList.
    this.selectedMedia.splice(imageIndex, 1);
    if (this.mediaFiles?.length < 3 && this.mediaFilesOverlay?.length) {
      this.mediaFiles.push(this.mediaFilesOverlay[0]);
      this.selectedMedia.push(this.mediaFilesOverlay[0]);
      this.mediaFilesOverlay.shift();
      if (!this.mediaFilesOverlay?.length) {
        this.showOverlayFlag = false;
      }
    }
    if (!this.mediaFiles?.length && !this.addRouteForm.controls['annotationDesc'].value) {
      this.addRouteForm.clearValidators();
      this.markFieldsAsRequired = false;
    }
  }

  /**
   * @description called when we click on Change to Round Trip button. 
   * Autosets destination and changes the icon on map abd carousel to indicate round trip.
   */
  public changeToRoundTripOnClk(): void {
    this.isRoundTrip = this.mediaCarousalService.isRoundTripSelected = true;
    this.currentStep = ETOUR_CREATION_STEPS.DESTINATION_ADDED;
    this.sendHighlightStep.emit(this.currentStep);
  }

  /**
   * @description Controls display of the modal title each time step changes
   * @returns title
   */
  public getStepwiseModalTitle(): string {
    switch (this.currentStep) {
      case ETOUR_CREATION_STEPS.SECOND:
      case ETOUR_CREATION_STEPS.START_ADDED:
      case ETOUR_CREATION_STEPS.THIRD:
        if (!this.startLocationButtonClickedFlag) {
          return LOCATIONS_CONSTANTS.LEFT_PANEL_TITLES.ADD_START_TITLE;
        } else {
          return LOCATIONS_CONSTANTS.LEFT_PANEL_TITLES.ADD_DESTINATION_TITLE;
        }
      case ETOUR_CREATION_STEPS.ADD_WAY_POINT:
      case ETOUR_CREATION_STEPS.ADD_WAY_POINT_DETAILS:
        if (this.editActive && this.editAfterPublishActive) {
          return LOCATIONS_CONSTANTS.LEFT_PANEL_TITLES.EDIT_INSIDER_SPOT_TITLE;
        } else {
          return LOCATIONS_CONSTANTS.LEFT_PANEL_TITLES.ADD_INSIDER_SPOT_TITLE;
        }
      case ETOUR_CREATION_STEPS.DESTINATION_ADDED:
      case ETOUR_CREATION_STEPS.WAY_POINT_ADDED:
      case ETOUR_CREATION_STEPS.EDIT_AFTER_PUBLISH:
        return LOCATIONS_CONSTANTS.LEFT_PANEL_TITLES.TOUR_DETAILS_TITLE;
      case ETOUR_CREATION_STEPS.EDIT_START:
      case ETOUR_CREATION_STEPS.EDITING_START:
          return LOCATIONS_CONSTANTS.LEFT_PANEL_TITLES.UPDATE_START_TITLE;
      case ETOUR_CREATION_STEPS.EDIT_DESTINATION:
        case ETOUR_CREATION_STEPS.EDITING_DESTINATION:
          return LOCATIONS_CONSTANTS.LEFT_PANEL_TITLES.UPDATE_DESTINATION_TITLE;
      default:
        return LOCATIONS_CONSTANTS.LEFT_PANEL_TITLES.ADD_START_TITLE;
    }
  }

  /**
   * @description sets name of the primary button at each step
   * @returns primary button name
   */
  public getPrimaryButtonName(): string {
    switch (this.currentStep) {
      case ETOUR_CREATION_STEPS.SECOND:
      case ETOUR_CREATION_STEPS.THIRD:
      case ETOUR_CREATION_STEPS.START_ADDED:
        if ((!this.startLocationButtonClickedFlag && !this.editActive)) {
          return LOCATIONS_CONSTANTS.BUTTON_NAMES.SET_AS_START
        } else {
          return LOCATIONS_CONSTANTS.BUTTON_NAMES.SET_AS_DESTINATION
        }
      case ETOUR_CREATION_STEPS.ADD_WAY_POINT:
      case ETOUR_CREATION_STEPS.ADD_WAY_POINT_DETAILS:
      case ETOUR_CREATION_STEPS.EDIT_DESTINATION:
      case ETOUR_CREATION_STEPS.EDIT_START:
      case ETOUR_CREATION_STEPS.EDITING_START:
      case ETOUR_CREATION_STEPS.EDITING_DESTINATION:
        if (this.editActive) {
          return AppConstants.COMMON_BUTTON_LABELS.SAVE;
        } else {
          return LOCATIONS_CONSTANTS.BUTTON_NAMES.SAVE_INSIDER_SPOT;
        }
      case ETOUR_CREATION_STEPS.WAY_POINT_ADDED:
      case ETOUR_CREATION_STEPS.EDIT_AFTER_PUBLISH:
      case ETOUR_CREATION_STEPS.DESTINATION_ADDED:
        if (!this.tourPublished) {
          return LOCATIONS_CONSTANTS.BUTTON_NAMES.GENERATE_TOUR;
        } else {
          return LOCATIONS_CONSTANTS.BUTTON_NAMES.PUBLISH_TOUR;
        }      
      default:
        return '';
    }
  }

  /**
   * @description sets name of the secondary button at each step
   * @returns name of secondary button
   */
  public getSecondaryButtonName(): string {
    switch (this.currentStep) {
      case ETOUR_CREATION_STEPS.THIRD:
      case ETOUR_CREATION_STEPS.ADD_WAY_POINT_DETAILS:
        if(!this.editAfterPublishActive){
          return this.editActive && this.addMoreDetailsFlag ? this.buttonNames.DELETE : LOCATIONS_CONSTANTS.BUTTON_NAMES.ADD_DETAILS;
        } else{
          return this.buttonNames.DELETE
        }
      case ETOUR_CREATION_STEPS.WAY_POINT_ADDED:
      case ETOUR_CREATION_STEPS.DESTINATION_ADDED:
        return this.editActive ? this.buttonNames.DELETE : this.buttonNames.CANCEL;
      case ETOUR_CREATION_STEPS.EDIT_AFTER_PUBLISH:
        return this.buttonNames.CANCEL;
      case ETOUR_CREATION_STEPS.EDIT_START:
      case ETOUR_CREATION_STEPS.EDIT_DESTINATION:
        return LOCATIONS_CONSTANTS.BUTTON_NAMES.ADD_DETAILS;
      default:
        return '';
    }
  }

  /**
   * @description This method controls the visibility of the secondary button at each step
   * @returns boolean
   */
  public showSecondaryBtnRight(): boolean {
    switch (this.currentStep) {
      case ETOUR_CREATION_STEPS.ADD_WAY_POINT_DETAILS:
        if ((!this.editHighlightPin && !this.addMoreDetailsFlag) || this.editActive) {
          return true;
        }
        break;
      case ETOUR_CREATION_STEPS.THIRD:
      case ETOUR_CREATION_STEPS.EDITING_START:
      case ETOUR_CREATION_STEPS.EDITING_DESTINATION:
      case ETOUR_CREATION_STEPS.EDIT_START:
      case ETOUR_CREATION_STEPS.EDIT_DESTINATION:
        if (!this.editAfterPublishActive && !this.addMoreDetailsFlag) {
          return true;
        }
        break;
      default:
        if (this.editActive && (this.selectedLocation.index !== 'start' && this.selectedLocation.index !== 'destination') && !this.editAfterPublishActive) {
          return true;
        }
        if (this.editActive && this.currentStep === 'ADD_WAY_POINT_DETAILS' && this.editAfterPublishActive) {
          return true;
        }
        break;
    }
    return false;
  }

  /**
   * @description During each step, perform necessary actions on primary button click
   */
  public decidePrimBtnActnOnClk(): void {
    switch (this.currentStep) {
      case ETOUR_CREATION_STEPS.SECOND:
      case ETOUR_CREATION_STEPS.THIRD:
      case ETOUR_CREATION_STEPS.START_ADDED:
      case ETOUR_CREATION_STEPS.EDIT_START:
      case ETOUR_CREATION_STEPS.EDIT_DESTINATION:
      case ETOUR_CREATION_STEPS.EDITING_START:
      case ETOUR_CREATION_STEPS.EDITING_DESTINATION:
        this.saveStartOrDestinationOnClk();
        break;
      case ETOUR_CREATION_STEPS.ADD_WAY_POINT_DETAILS:
        this.sendWaypointData();
        break;
      case ETOUR_CREATION_STEPS.EDIT_AFTER_PUBLISH:
        if (this.tourName === '') {
          this.toastService.show(ToastType.Error, LOCATIONS_CONSTANTS.TOAST_ERROR_MSGS.ET_NAME_REQ, '1%', '40%');
          setTimeout(() => {
            this.toastService.hide();
          }, AppConstants.MIN_TIME_TOASTER);
          return;
        }
        let tourData:TourDetailsUpdateRequest = { guid: this.tourInfoPostPublish?.guid, description:this.tourDesc,visibility:'PERMANENT' };
        if(this.tourInfoPostPublish?.tourName !== this.tourName){
          tourData.name = this.tourName
        }
        this.publishTour(tourData);
        this.currentStep = 'TOUR_PUBLISHED';
        break;
      case ETOUR_CREATION_STEPS.DESTINATION_ADDED:
      case ETOUR_CREATION_STEPS.WAY_POINT_ADDED:
        if (!this.tourPublished) { this.fetchCategories(); }
        else if(this.editAfterPublishActive){ 
          let tourData:TourDetailsUpdateRequest = { guid: this.tourInfoPostPublish?.guid, description:this.tourDesc,visibility:'PERMANENT' };
        if(this.tourInfoPostPublish?.tourName !== this.tourName){
          tourData.name = this.tourName
        }
        this.publishTour(tourData);
        this.currentStep = 'TOUR_PUBLISHED';
         } else{
          this.publishTour(this.tourForm);

         }
    }
  }

  /**
   * @description During each step, perform necessary action on secondary button click
   */
  public decideSecBtnActnOnClk(action: string): void {
    switch (this.currentStep) {
      case ETOUR_CREATION_STEPS.THIRD:      
      case ETOUR_CREATION_STEPS.EDIT_START:
      case ETOUR_CREATION_STEPS.EDIT_DESTINATION:
          this.addMoreDetailsOnClk(this.editActive);
        break;
      case ETOUR_CREATION_STEPS.DESTINATION_ADDED:
      case ETOUR_CREATION_STEPS.WAY_POINT_ADDED:
        const msg = this.isOffroadTourFlow ? LOCATIONS_CONSTANTS.CONFIRMATION_POPUP_MSGS.DISCARD_OFFROAD_TRACK_MSG : LOCATIONS_CONSTANTS.CONFIRMATION_POPUP_MSGS.DISCARD_EMOTION_TOUR_MSG;
        const cancelDialogTitle = this.isOffroadTourFlow ? LOCATIONS_CONSTANTS.ALERT_POPUP_TITLE.CANCEL_OFFROAD_TRACK_TITLE : LOCATIONS_CONSTANTS.ALERT_POPUP_TITLE.CANCEL_EMOTION_TOUR_TITLE;
        this.showDiscardCurrentStepModal(cancelDialogTitle,msg);
        break;
      case ETOUR_CREATION_STEPS.EDIT_AFTER_PUBLISH:
        this.mediaCarousalService.CancelCurrentStep.next('EDIT_AFTER_PUBLISH_CANCELLED');
        this.goBackToPublishStage();
        this.tourDesc = this.tourInfoPostPublish?.tourDescription?this.tourInfoPostPublish?.tourDescription:'';
        this.tourName = this.tourInfoPostPublish?.tourName?this.tourInfoPostPublish?.tourName:'';
        break;
      case ETOUR_CREATION_STEPS.ADD_WAY_POINT_DETAILS:
        if(this.editAfterPublishActive){
          this.currentStep = 'EDIT_AFTER_PUBLISH';
          let waypointDetailsForDelete = {
            index: (this.editedPinIndex - 1),
            name: this.searchResult.name,
            annotationId: this.searchResult.id
          }
          this.sendDeleteCardFlowTrigger.emit(waypointDetailsForDelete);
          this.selectedLocation = '';
          this.editActive = false;
          this.mediaCarousalService.CancelCurrentStep.next("EDIT_AFTER_PUBLISH");
        } else{
          if (this.editActive && (this.editedHighlightInfo || this.addMoreDetailsFlag)) {
            this.mediaCarousalService.decideModalAction = action;
          } else {
            this.addMoreDetailsOnClk(this.editActive);
          }
        }
        break;
    }
  }

  /**
   * @description Property binding from HTML file, tells if the primary button has to be enabled/disabled during each step
   * @returns disabled button state
   */
  public getDisabledPrimaryBtn(): boolean {
    switch (this.currentStep) {
      case ETOUR_CREATION_STEPS.THIRD:
      case ETOUR_CREATION_STEPS.ADD_WAY_POINT_DETAILS:
        case ETOUR_CREATION_STEPS.EDITING_START:
      case ETOUR_CREATION_STEPS.EDITING_DESTINATION:
      case ETOUR_CREATION_STEPS.EDIT_START:
      case ETOUR_CREATION_STEPS.EDIT_DESTINATION:
          return (this.disableUpdateBtn || (this.addMoreDetailsFlag && (this.addRouteForm?.invalid || 
            (this.markFieldsAsRequired && !this.mediaFiles?.length))) ? true: false);
      case ETOUR_CREATION_STEPS.DESTINATION_ADDED:
      case ETOUR_CREATION_STEPS.WAY_POINT_ADDED:
        if (!this.tourPublished) {
          return this.disableGenerateButton || !this.tourName
        } else {
          return this.disablePublishButton;
        }      
      default: 
      return false;
    }
  }

  /**
   * @description Property binding from HTML file, tells if the secondary button has to be enabled/disabled during each step
   * @returns disabled state of button
   */
  public getDisabledSecondatyBtn(): boolean {
    switch (this.currentStep) {
      case ETOUR_CREATION_STEPS.ADD_WAY_POINT_DETAILS:
      case ETOUR_CREATION_STEPS.THIRD:
      case ETOUR_CREATION_STEPS.EDIT_DESTINATION:
      case ETOUR_CREATION_STEPS.EDIT_START:
        if (this.showSecondaryBtnRight()) {
          return false;
        } else {
          return true;
        }
      case ETOUR_CREATION_STEPS.DESTINATION_ADDED:
      case ETOUR_CREATION_STEPS.WAY_POINT_ADDED:
        if (this.tourPublished) {
          return this.disablePublishButton;
        } else {
          return false;
        }
    }
    return false;
  }

  /**
   * @description Called when Discard is clicked from Alert Popup. Takes suitable action based on dealer choice.
   * @param data 
   */
  public handleModalSubscriptions(data: any): void {
    // Modal's Confirm Action
    const initiateStepwiseCancelProcess = () => {
      switch (this.currentStep) {
        case ETOUR_CREATION_STEPS.SECOND:
          if (this.startLocationButtonClickedFlag) {
            this.router.navigateByUrl(this.navigateUrl);
          }
          break;
        case ETOUR_CREATION_STEPS.START_ADDED:
        case ETOUR_CREATION_STEPS.THIRD:
        case ETOUR_CREATION_STEPS.DESTINATION_ADDED:
        case ETOUR_CREATION_STEPS.WAY_POINT_ADDED:
          this.router.navigateByUrl(this.navigateUrl);
          break;
        case ETOUR_CREATION_STEPS.ADD_WAY_POINT_DETAILS:
          // Take to Generate Step
          this.abortDataSendOnClick.emit(true);
          this.cancelCurrentWaypoint.emit('waypoint' + this.index);
          this.sendWaypointDetails.emit(this.wayPointData);
          this.resetUploadMedia();
          this.resetFormData();
          this.mediaCarousalService.resetMediaWithCopyRightData();
          this.currentStep = ETOUR_CREATION_STEPS.WAY_POINT_ADDED;
          this.mediaCarousalService.CancelCurrentStep.next(this.currentStep);
          this.highlightDesc  = this.highlightName = ''; 
          this.sendHighlightStep.emit(this.currentStep);
          this.mediaCarousalService.showPlusIconOnMapHover.next(false);
          this.addMoreDetailsFlag = false; 
          break;
        case ETOUR_CREATION_STEPS.EDIT_AFTER_PUBLISH: 
        this.takeToEditPostPublishStage();
        break;

      }
    }
    // Modal's confirm flag actions
    if (data.action && this.currentStep) {
      switch (data['flag']) {
        case EMODAL_PRIMARY_ACTIONS.CANCEL_EMOTION_TOUR:
          if (this.currentStep === ETOUR_CREATION_STEPS.START_ADDED) {
            this.mediaCarousalService.CancelCurrentStep.next(ETOUR_CREATION_STEPS.SECOND);
          }
          this.router.navigateByUrl(this.navigateUrl);
          break;
        case EMODAL_PRIMARY_ACTIONS.CANCEL_INSIDER_SPOT:
          initiateStepwiseCancelProcess();
          break;
        case EMODAL_PRIMARY_ACTIONS.DELETE_BUSINESS_TOUR:
          this.deleteBusinessTourOnConfirm();
          break;
        case EMODAL_PRIMARY_ACTIONS.DISABLE_EDIT:
        case EMODAL_PRIMARY_ACTIONS.DELETE_INSDIER_SPOT:
          this.mediaService.setEditActiveStatus(false);
          this.currentStep = ETOUR_CREATION_STEPS.WAY_POINT_ADDED;
          this.editActive = this.editHighlightPin = false;
          this.editedHighlightInfo = null;
          this.resetFormData();
          this.resetUploadMedia();
          this.mediaCarousalService.resetMediaWithCopyRightData();
          this.hideSelectedCardDetails();
          this.selectedLocation = ''
          this.searchResult = ''
          break;
      }
    }
  }

  public showPrimaryBtn(): boolean {
    switch(this.currentStep) {
      case ETOUR_CREATION_STEPS.SECOND:
      case ETOUR_CREATION_STEPS.START_ADDED:
      case ETOUR_CREATION_STEPS.ADD_WAY_POINT:
      case ETOUR_CREATION_STEPS.EDITING_DESTINATION:
      case ETOUR_CREATION_STEPS.EDITING_START:
        return false;
      default:
      return this.viewSelectedCardDetails ? false: true;
    }
  }

  public showSearchResult(): boolean {
    if (this.editAfterPublishActive) {
      return (
        (this.currentStep === ETOUR_CREATION_STEPS.ADD_WAY_POINT ||
          this.currentStep === ETOUR_CREATION_STEPS.ADD_WAY_POINT_DETAILS) &&
        !this.editActive
      );
    } else {
      return (
        (this.currentStep !== 'DESTINATION_ADDED' &&
          this.currentStep !== ETOUR_CREATION_STEPS.EDIT_AFTER_PUBLISH
          && this.currentStep !== ETOUR_CREATION_STEPS.EDIT_START &&
          this.currentStep !== ETOUR_CREATION_STEPS.EDIT_DESTINATION &&
          this.currentStep !== 'WAY_POINT_ADDED') &&
        (!this.editActive || !(this.editActive && this.editedHighlightInfo))
      );
    }
  }

  changeStepToEdit(){
    const currentUrl = this.router.url;
    if (!this._actRoute.snapshot.routeConfig?.path?.includes(':trackType/:guid/edit')) {
      this._location.replaceState(currentUrl + '/edit');
    }
    this.currentStep = 'EDIT_AFTER_PUBLISH';
    this.displayPublishedDetails = false;
    this.tourPublishedView = false;
    this.sendEditFlowTrigger.emit(true);
    this.editAfterPublishActive = true;

    this.tourDesc = this.tourDesc? this.tourDesc : this.tourInfoPostPublish?.tourDescription??'';
    this.tourName = this.tourName? this.tourName : this.tourInfoPostPublish?.tourName??'';

    this.mediaCarousalService.CancelCurrentStep.next(this.currentStep);
    this.mediaService.setEditActiveStatus(false);
    // Issue: Dropdowns are open even after I have come back from Edit
    this.displayGpxMenu = this.isMenuDropdownVisible = false;
  }

  goBackToPublishStage(){
    this.currentStep = 'TOUR_PUBLISHED';
    this.displayPublishedDetails = true;
    this.tourPublishedView = true;    
    this.editAfterPublishActive = false;
    this.sendEditFlowTrigger.emit(false);
    this.toggleDropdownMenuOptions('hide');
    this.mediaCarousalService.CancelCurrentStep.next("");
    this.mediaService.clearMediaValue();
    const trackType = this._actRoute.snapshot.params['trackType'];
    this._location.replaceState(`/${this.languageSelected}/locations/${trackType}/${this.tourInfoPostPublish?.guid}`);
  }

  getMedia() {
    this.mediaFiles = [];
    this.subscribeMediaData =
      this.mediaService.mediaValueForTrailCover$.subscribe((data: any) => {
        if(data.length > 0){
          this.mediaFiles.push(data[0]);
        }        
      });
  }




  showAudioUploadDialog(event: boolean): void {
    this.isAudioUploadVisible = event;
  }

  /**
   * @description Called when we click on confirm button from image gallery dialog
   */
  public showCopyrightDialog(): void {
    const mediaUploaded = this.mediaCarousalService.getImagesWithCopyRightData();
    if (mediaUploaded.length) {
      this.mediaFiles = mediaUploaded
        .filter((media: any) => media.originalMedia instanceof File)
        .map((media: any) => media.originalMedia);
    }
  }

  public initiateGpxDownloadFlow(action: string, event?: Event): void {
    if (this.availableTrackVersions.length > 1) {
      if (action === 'show' ) {
        this.getGpxButtonPosition();
        this.isMenuDropdownVisible = false;
        this.displayGpxMenu = !this.displayGpxMenu;
      } else if (action === 'hide') {
        this.displayGpxMenu = false;
      }
    } else if (action === 'download') {
      this.downloadBusinessTourDetails();
    }
    if (event) { event.stopImmediatePropagation(); }
  }



  ngOnDestroy() {
    this.subscription.forEach(subs => subs.unsubscribe());
    this.getEditStatus.unsubscribe();
    this.mediaCarousalService.isRoundTripSelected = false;
    this.mediaCarousalService.resetMediaWithCopyRightData();
  }
  
  public uploadMediaEmitter() {
    this.mediaCarousalService.copyrightInfoDialog.subscribe((response: any) => {
      this.showCopyrightDialogBoxWithSavedInfo.saveImgWithCopyright = response.saveData;
      this.showCopyrightDialogBoxWithSavedInfo.showModal = response.showModal;
      if (!response.saveData && response.showModal) {
        this.showCopyrightDialog()
      } else if (!response.showModal){
        const existingMedia =  this.mediaCarousalService.getImagesWithCopyRightData();
        this.mediaFiles = [...this.mediaFiles, ...existingMedia];
      }
    })
  }

  public markFieldsAsRequiredForImgUpload() {
    this.addRouteForm.controls['annotationDesc'].setValidators([Validators.required]);
    this.addRouteForm.controls['annotationDesc'].updateValueAndValidity();
    this.markFieldsAsRequired = true;
  }

 public updatingMediaFiles (event: any){
    this.mediaFiles.splice(event, 1);
    if (!this.mediaFiles?.length && !this.addRouteForm.controls['annotationDesc'].value) {
      this.addRouteForm.clearValidators();
      this.markFieldsAsRequired = false;
    }
}


}
