<div class="in-page-main">
    <ng-container *ngIf="!showNotFoundPage">
        <div>
            <div *ngIf="showToast" class="toast-style">
                <app-toast [message]="toastMessage" [toastType]="toastType" [position]="position" [leftPosition]="leftPosition" [placeholderObject] = "placeholderObject" >
                </app-toast>
            </div>
            <app-layout-details-header [primaryButtonTitle]="primaryButtonTitle" [title]="trackName"
                [secondaryButtonTitle]="secondaryButtonTitle" [primaryButtonColor]="primaryButtonColor"
                (primaryButtonClick)="primaryButtonClick()" (secondaryButtonClick)="secondaryButtonClick()">
            </app-layout-details-header>
        </div>
        <div class="location-sub-details-inner">
            <div class="web-portal-container">
                <div class="sub-content">
                    <div class="lt-row-w-g">
                        <div class="clm-4 clm-tab-4">
                            <div *ngIf="this.circuitData" class="map-height">
                                <div (click)="editGeoFence()" class="edit-button">
                                    {{'BP_LM_EDIT_GEOFENCE_BTN_LBL' | customTranslation:translationData}}
                                </div>
                                <app-circuit-leaflet-map [apiKey]="apiKey" [location]="this.circuitData?.location"
                                    [isShowFence]="isShowFence" [radius]="circuitRadius"
                                    [isInteractionEnabled]="isInteractionEnabled"
                                    [isZoomControlEnabled]="isZoomControlEnabled" [showLayerSwitchMenu]="showLayerSwitchMenu" [isShowCircuitInfo]="isShowCircuitInfo">
                                </app-circuit-leaflet-map>
                            </div>
                            <div id="cropModal" class="cropper-modal">
                                <div class="cropper-wrap">
                                    <div class="modal-header">
                                        <div class="modal-title">
                                            {{'BP_EM_CROP_COVERIMG' | customTranslation:translationData}}
                                        </div>
                                        <div class="button-region">
                                            <button class="hallow" (click)="closeModal()"><span>{{buttonNames.CANCEL | customTranslation:translationData}}</span></button>
                                            <button class="beta" (click)="loadCroppedImage()"><span>{{buttonNames.DONE | customTranslation: translationData}}</span></button>
                                        </div>
    
                                    </div>
                                    <div class="img-cropper-wrapper">
                                        <image-cropper [imageBase64]="imageBase64String" [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true"
                                            [aspectRatio]="16 / 9" [cropperMinHeight]="175" [cropperMinWidth]="398" [backgroundColor]=backgroundColor
                                            [resizeToWidth]="1000"
                                            [format]="'jpeg'" [imageQuality]="60" (imageCropped)="imageCropped($event)" (imageLoaded)="imageLoaded()"
                                            [containWithinAspectRatio]="containWithinAspectRatio" (loadImageFailed)="loadImageFailed()">
                                        </image-cropper>
                                    </div>
                                </div>
                            </div>
                            <div class="media-content">{{'BP_CM_ADD_COVER_IMG' | customTranslation:translationData}}</div>
                            <ng-container>
                            <div class="img-upload-container" appDragDropFileUpload (click)="bannerfileField.click()"
                                (fileDropped)="fileChangeEvent($event)"
                                *ngIf="imageUrls && imageUrls.length===0 && !finalCroppedImage">
                                <div  for="bannerfileField">
                                    <span class="icon big upload-image"></span>
                                </div>
                                <div class="img-upload-text">
                                    <input class="image-input-class" type="file" #bannerfileField (click)="bannerfileField.value = ''" 
                                    (change)="fileChangeEvent($event)" accept=".jpg, .jpeg, .png" />
                                    <span >{{'BP_EM_DRAG_DROP' | customTranslation : translationData }} <strong class="heightlight-color">{{'BP_CM_BROWSE_BTN_LBL' | customTranslation : translationData }}</strong> {{'BP_CM_UPLOAD_COVER_IMAGE' | customTranslation : translationData }}</span>
                                </div>
                                <app-progressbar [progress]="progress" *ngIf="showProgress"></app-progressbar>
                            </div>
                            <div class="img-upload-container after-image" *ngIf="imageUrls && imageUrls.length>0">
                                <img [src]="this.imageUrls[0].thumbnailUrl ?? this.imageUrls[0] | safe" class="image-preview" alt="..."/>
                                <div class="image-action">
                                    <div class="change-image" (click)="bannerField.click()">
                                        {{locationConstants.HELPER_INPUT_LABELS.CHANGE_IMG| customTranslation:translationData}}
                                    </div>
                                    <div class="delete-image" (click)="deleteImage(imageUrls[0].id)">
                                        {{'BP_LM_DELETE_COVER_IMAGE' | customTranslation:translationData}}
                                    </div>
                                    <input class="d-none" type="file" #bannerField (change)="fileChangeEvent($event)"  accept=".jpg, .jpeg, .png, .gif,.mp4" />

                                </div>
                                <input class="d-none" type="file" #bannerField (change)="fileChangeEvent($event)"  accept=".jpg, .jpeg, .png, .gif,.mp4" />
                            </div>

                            </ng-container>
                        </div>
                        <div class="clm-8 clm-tab-4">
                            <div *ngIf="circuitData" class="form-padding">
                                <app-circuit-details-form [circuitData]="circuitData" [imageUrls]="imageUrls"></app-circuit-details-form>
                            </div>
    

                        </div>
                    </div>
                </div>
            </div>
        </div>
        <app-common-alert-modal [title]="title" [message]="message" [cancelBtnTitle]="cancelBtnTitle" [actionBtnTitle]="actionBtnTitle" 
        [isModalOpen]=isModalOpen [actionType]="actionType" (isModalResponseClicked)="getCancelModalResponse($event)">
        </app-common-alert-modal>
    </ng-container>
    </div>

    <div *ngIf="showNotFoundPage">
        <app-page-not-found></app-page-not-found>
    </div>

