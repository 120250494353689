import { ASSET_TYPES, BusinessTourPreview, ELAND_MARK } from './../../interfaces/business-tour-create.interface';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LOCATIONS_CONSTANTS } from '../../interfaces/locations-constants';
import { AppConstants } from 'src/app/shared/constants/constants';
import { CommonModalService } from 'src/app/modules/events/services/common-modal.service';
import { TranslationService } from 'src/app/shared/service/translation.service';
import { MediaCarouselService } from '../../services/media/media-carousel.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LocationsHttpService } from '../../services/locations-http.service';
import { CopyrightRequestWithMedia, PreSignedUrlResponse } from '../../interfaces/amg-annotation.interface';
import { LoadingSpinnerService } from 'src/app/shared/service/loading-spinner.service';
import { ToastService, ToastType } from 'src/app/shared/service/toast.service';
import { ActivatedRoute } from '@angular/router';
import { CircuitsServicesService } from '../../services/circuits-services.service';
import { HttpResponse } from '@angular/common/http';
import { from } from 'rxjs';

@Component({
  selector: 'app-image-copyright-overlay',
  templateUrl: './image-copyright-overlay.component.html',
  styleUrls: ['./image-copyright-overlay.component.scss']
})
export class ImageCopyrightOverlayComponent implements OnInit {

  public mediaList: CopyrightRequestWithMedia[] = [];
  public originalMediaOnLoad: CopyrightRequestWithMedia[] = [];

  public dialogData = {
    secondaryActionBtnTitle: AppConstants.COMMON_BUTTON_LABELS.CLOSE,
    primaryActionBtnTitle: AppConstants.COMMON_BUTTON_LABELS.CONFIRM,
    title: LOCATIONS_CONSTANTS.ALERT_POPUP_TITLE.IMAGE_COPYRIGHT_DETAILS,
    type: 'large-popup'
  }
  public buttonNames = AppConstants.COMMON_BUTTON_LABELS;
  public LOCATIONS_CONSTANTS_LIST: any = LOCATIONS_CONSTANTS;
  public urlRegex = this.LOCATIONS_CONSTANTS_LIST.URL_VALIDATION_REGEX_PATTERN;
  public translationData: any;
  public trailGuid: string = '';

  public copyrightForm: FormGroup = this._formBuilder.group({});

  // Input and Output decorators go here
  @Input() public asset_type: string = ASSET_TYPES.TRACK_COVER
  @Input() public guid_trail: string = ''
  @Output() public showModal = new EventEmitter<{
    showModal: boolean,
    saveImgWithCopyright: boolean
  }>();
  isFormControlsUpdated: boolean = false;

  constructor(private _commonModalService: CommonModalService, 
    private _translationService: TranslationService,
    private _commomMediaService: MediaCarouselService, 
    private _locationsService: LocationsHttpService,
    private _formBuilder: FormBuilder,
    private _toastService: ToastService,
    private _activeRoute: ActivatedRoute,
    private _circuitsService: CircuitsServicesService,
    private cdr: ChangeDetectorRef,
    private _loadingSpinnerService: LoadingSpinnerService) {}

  public ngOnInit(): void {
    this.trailGuid = this._activeRoute.snapshot.params['guid'] ?? this.guid_trail;
    this._translationService.getTranslationDataAsObservable().subscribe((translationResponse: any) => {
      this.translationData = translationResponse;
    });
    this.initializeCopyrightDialog()
  }

  /**
   * @description Calling this function on load of dialog data
   * This will populate form data
   */
  public initializeCopyrightDialog(): void {
    this._commonModalService._modalHeaderIsCloseBtnVisible.next(true)
    this._commonModalService._modalHeaderIsSaveBtnVisible.next(true)
    this._commonModalService._modalHeaderTitle.next(this.dialogData.title);
    this.populateCopyRightDataOnForm();
  }

  /**
   * @description Method to populate copy right data on form
   * Since we can have multiple images and 1 image can corresponsding copy right data
   * Using indexing approach to create form controls, each form control will have index in its name
   */
  public populateCopyRightDataOnForm(): void {
    this.mediaList = [...this._commomMediaService.getImagesWithCopyRightData()];
    this.originalMediaOnLoad = [...this.mediaList];
    const controls: any = {}
    this.mediaList.forEach((media: {originalMedia: any, media: any, copyRightData: any}, i: number) => {
      controls['attribution' + i] = [media.copyRightData?.attribution || ''];
      controls['license' + i] = [media.copyRightData?.license || ''];
      controls['license_url' + i] = [media.copyRightData?.license_url || '', [Validators.pattern(this.urlRegex)]];
      controls['subject_url' + i] = [media.copyRightData?.subject_url || '', [Validators.pattern(this.urlRegex)]];
    });
    console.log('controls :>> ', controls);
    this.copyrightForm = this._formBuilder.group({
      licenses: this._formBuilder.group(controls)
    });

    this.callGetPresignedUrlApi();
  }

  /**
   * @description Calling this method to update form control indexs post removal/deletion of an image
   */
  public updateFormControlsForRemove(): void {
    const controls: any = {};  
    this.mediaList.forEach((image, index) => {
      controls['attribution' + index] = [image.copyRightData?.attribution || ''];
      controls['license' + index] = [image.copyRightData?.license || ''];
      controls['license_url' + index] = [image.copyRightData?.license_url || '', [Validators.pattern(this.urlRegex)]];
      controls['subject_url' + index] = [image.copyRightData?.subject_url || '', [Validators.pattern(this.urlRegex)]];
    });
  
    this.copyrightForm.setControl('licenses', this._formBuilder.group(controls));
    this.cdr.detectChanges();
  }

  /**
   * @description This method is called once we upload 1 or more images from the Image copyright popup
   * Using this method I am adding new index against each form control.
   */
  public updateFormControlsForAdd(): void {
    this.mediaList = [...this._commomMediaService.getImagesWithCopyRightData()];
    this.isFormControlsUpdated = true
    const licensesGroup = this.copyrightForm.get('licenses') as FormGroup;
    this.mediaList.forEach((image, index) => {
      if (!licensesGroup.get('attribution' + index)) {
        licensesGroup.addControl('attribution' + index, this._formBuilder.control(image.copyRightData?.attribution || ''));
      }
      if (!licensesGroup.get('license' + index)) {
        licensesGroup.addControl('license' + index, this._formBuilder.control(image.copyRightData?.license || ''));
      }
      if (!licensesGroup.get('license_url' + index)) {
        licensesGroup.addControl('license_url' + index, this._formBuilder.control(image.copyRightData?.license_url || '', [Validators.pattern(this.urlRegex)]));
      }
      if (!licensesGroup.get('subject_url' + index)) {
        licensesGroup.addControl('subject_url' + index, this._formBuilder.control(image.copyRightData?.subject_url || '', [Validators.pattern(this.urlRegex)]));
      }
    });
  }

  /**
   * @description Method to save image with copy right data
   * Called on click of confirm button
   * @returns void
   */
  public saveImageWithCopyRightData(): void {
    if (this.copyrightForm.invalid || !this.mediaList.length) {
      return;
    }
    const formValues = this.copyrightForm.value.licenses;
    this._loadingSpinnerService.show();
    this.mediaList = [...this.mediaList.map((image, index) => ({
      ...image,
      copyRightData: {
        attribution: formValues['attribution' + index],
        license: formValues['license' + index],
        license_url: formValues['license_url' + index],
        subject_url: formValues['subject_url' + index]
      }
    }))];
    this.uploadMediaFiles();
  }

  /**
   * @description Calling this function when the user is uploading images from the dialog
   * @param event of type File
   * @returns void
   */
  public async onFileUpload(event: Event): Promise<void> {
    const input = event.target as HTMLInputElement;
    const files: File[] = input.files ? Array.from(input.files) : [];
    if (!files.length) {
      return;
    }
    const imageValidity = this._commomMediaService.checkFilesValidation(files)
      if (imageValidity.isValidFiles) {
        this.isFormControlsUpdated = false;
        try {
          await this._commomMediaService.processImageUploaded(files, true);
          this.reinitializeCopyrightInfoDialog();
        } catch {
          console.error('Error processing image upload');
        }
      } else {
        this.showToastMsg(imageValidity.toastDetails?.toastType, imageValidity.toastDetails.message)
      }   
  }

  /**
   * @description Calling this function to update form controls and call pre-signed URLs
   * for newly uploaded images from the popup
   */
  public reinitializeCopyrightInfoDialog(): void {
    this._commomMediaService.copyrightInfoDialog.subscribe((data: {showModal: boolean, saveData: boolean, updateCopyrightOnFileSelect: boolean}) => {
      if (data?.updateCopyrightOnFileSelect && !this.isFormControlsUpdated) {
        this.updateFormControlsForAdd();
        this._commomMediaService.copyrightInfoDialog = {
            showModal: true,
            saveData: false,
            updateCopyrightOnFileSelect: false
          };
        this.callGetPresignedUrlApi();
      }
    });
  }

  /**
   * @description Method to call getPresignedURL API. It will give us a list of URLs
   * that we can call and upload images to blob storage
   * @returns void
   */
  public callGetPresignedUrlApi(): void {
    const fileNames: string[] = this.getMediaFilesForUpload()
    .map(media => media.originalMedia.name);
    if (!fileNames.length) {
      return;
    }

    // If there are no images uploaded, but for some existing image copytright data is updated, even then call finalize upload
    // This is to close the dialog and also to update the copy right data to send to API
    this._loadingSpinnerService.show();
    this._locationsService.getPreSignedUrls(fileNames, this.asset_type).subscribe({
      next: (response: HttpResponse<any>) => {
        if (response) {
          this._commomMediaService.copyrightInfoDialog = {
            showModal: true,
            saveData: false,
            updateCopyrightOnFileSelect: false
          };
          this.processPresignedUrlsResponse(response.body, fileNames);
          this._loadingSpinnerService.hide();
        }
      },
      error: (err: any) => {
        this._loadingSpinnerService.hide();
      }
    });
  }

  private processPresignedUrlsResponse(preSignedUrlresponse: PreSignedUrlResponse[], fileNames: string[]): void {
    if (preSignedUrlresponse.length !== fileNames.length) {
      this._loadingSpinnerService.hide();
      return;
    }
    const mediaFilesForUpload = this.getMediaFilesForUpload();
    this.updateMediaListWithPreSignedUrls(mediaFilesForUpload, preSignedUrlresponse);
  }
  
  /**
   * 
   * @returns List of media files that are not uploaded yet
   */
  private getMediaFilesForUpload(): CopyrightRequestWithMedia[] {
    return this.mediaList.filter((media) => media.originalMedia instanceof File && !media.hasOwnProperty('pre_signedUrl'));
  }
  
  /**
   * 
   * @param mediaFiles 
   * @param pre_signedUrls 
   * @returns list of media files with pre-signed URLs
   */
  private updateMediaListWithPreSignedUrls(mediaFiles: CopyrightRequestWithMedia[], pre_signedUrls: PreSignedUrlResponse[]): void {
    const updatedMediaFiles = mediaFiles.map((media, index) => ({
      ...media,
      pre_signedUrl: pre_signedUrls[index]
    }));
    this.mediaList = [...this.mediaList.map(media => {
      const updatedMedia = updatedMediaFiles.find(file => file.originalMedia.name === media.originalMedia.name);
      return updatedMedia ? updatedMedia : media;
    })];
    this._commomMediaService.updateImagesWithCopyRightData(this.mediaList);
  }
  
  private showToastMsg(type: any, msg: string): void {
    this._toastService.show(type, msg, '1%', '4%');
    setTimeout(() => {
      this._toastService.hide()
    }, AppConstants.MIN_TIME_TOASTER);
  }

  /**
   * @description Calling this function to upload files into it's respective pre-signed URL
   * that we recieved from pre-signed API; this is called post primary button click
   * @returns void
   */
  private uploadMediaFiles(): void {
    const filesForUpload: CopyrightRequestWithMedia[] = this.mediaList.filter(media => media.originalMedia instanceof File && media.hasOwnProperty('pre_signedUrl'));
    if (!filesForUpload.length) {
      this.finalizeUpload(filesForUpload);
      return;
    }
    filesForUpload.forEach((value, index) => {
      this._locationsService.uploadMediaFilesToBlobStorage(value.pre_signedUrl?.preSignedUrl ?? '', filesForUpload[index].originalMedia).subscribe({
        next: () => {
          if (index === filesForUpload.length - 1) {
            this.finalizeUpload(filesForUpload);
          }
        },
        error: (err: any) => {
          this.showToastMsg(ToastType.Error, LOCATIONS_CONSTANTS.TOAST_ERROR_MSGS.FILE_UPLOAD_ERR);
          this._loadingSpinnerService.hide();
        }
      });
    });
  }

  /**
   * @description Called post calling getPresignedURL API or updating copyright info
   * @param filesForUpload 
   */
  private finalizeUpload(filesForUpload: CopyrightRequestWithMedia[]): void {
    this.mediaList = [...this.mediaList.map((media, index) => {
      const fileForUpload = filesForUpload.find(file => file.originalMedia.name === media.originalMedia.name);
      return fileForUpload ? { ...media, pre_signedUrl: fileForUpload.pre_signedUrl } : media;
    })];
    this._commomMediaService.updateImagesWithCopyRightData(this.mediaList);
    if (this.asset_type === ASSET_TYPES.TRACK_COVER) {
      this.updateCopyrightData();
    } else {
      this._loadingSpinnerService.hide();
      this._commomMediaService.copyrightInfoDialog = {
        showModal: false,
        saveData: true,
        updateCopyrightOnFileSelect: false
      };
    }
  }

  /**
   * @description Calling this function to update copyright data to BE
   * Use track update v3 to update and geoJson to get latest details
   * Called post successful upload of all images into blob storage
   */
  private updateCopyrightData(): void {
    this._locationsService.UpdateTrackDetails("Admin", this.trailGuid, [], true)
      .subscribe({
        next: (updateTrackResponse: HttpResponse<any>) => {
          if (updateTrackResponse) {
            this._locationsService.getEmotionTourWithAnnotations(this.trailGuid, 'location_module')
              .subscribe({
                next: (geoJsonResponse: HttpResponse<any>) => {
                  if (geoJsonResponse) {
                    this.processGeoJsonResponse(geoJsonResponse.body);
                    this.showToastMsg(ToastType.Success, LOCATIONS_CONSTANTS.TOAST_SUCCESS_MESSAGES.IMAGE_COPYRIGHT_SAVED_SUCCESS_MSG);
                    this._loadingSpinnerService.hide();
                    this._commomMediaService.copyrightInfoDialog = {
                      showModal: false,
                      saveData: true,
                      updateCopyrightOnFileSelect: false
                    };
                  }
                },
                error: (err: any) => {
                  console.error('Error fetching GeoJson response:', err);
                  this._loadingSpinnerService.hide();
                }
              });
          }
        },
        error: (err: any) => {
          console.error('Error updating track details:', err);
          this._loadingSpinnerService.hide();
        }
      });
  }

  public processGeoJsonResponse(geoJsonResponse: BusinessTourPreview): void {
    const route = geoJsonResponse.features.find((feature: any) => feature.properties.landmark_type === ELAND_MARK.ROUTE);
    this._commomMediaService.geoJsonDataMediaForCover = route?.properties?.media;
    this._circuitsService.sendCoverMediaValue(this._commomMediaService.geoJsonDataMediaForCover);
  }

  /**
   * @description Method to close the dialog. If I close popup, keep original data only
   * @returns void
   */
  public closeModal(): void {  
    const originalFiles = this.originalMediaOnLoad.filter((media) => !(media?.originalMedia instanceof File));
    this._commomMediaService.updateImagesWithCopyRightData(originalFiles);
    this._commomMediaService.copyrightInfoDialog = {
      showModal: false,
      saveData: false,
      updateCopyrightOnFileSelect: false
    };
  }

  /**
   * @description Method to remove image with copy right data
   * called when we click on x icon
   * @param mediaIndex 
   * @returns void
   */
  public removeImgWithCopyrightData(mediaIndex: number): void {
    if (mediaIndex < 0 || mediaIndex >= this.mediaList.length) {
      console.warn('Invalid media index:', mediaIndex);
      return;
    }
    this.mediaList = [...this.mediaList.filter((_, index) => index !== mediaIndex)];
    this._commomMediaService.updateImagesWithCopyRightData(this.mediaList);
    this.removeFormControls(mediaIndex);
    // Added this so that form controls updates are properly detected by HTML; removing this 
    // will lead to console errors and will not update copyright data correctly.
    this.cdr.detectChanges();
    this.updateFormControlsForRemove();
    if (this.mediaList.length === 0) {
      this.closeModal();
    }
  }

  public removeFormControls(mediaIndex: number): void {
    const licensesGroup = this.copyrightForm.get('licenses') as FormGroup;
    ['attribution', 'license', 'license_url', 'subject_url'].forEach(field => {
      licensesGroup.removeControl(field + mediaIndex);
    });
  }

}


