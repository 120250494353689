import { Location } from '@angular/common';
import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { CustomTranslationPipe } from 'src/app/shared/pipes/custom-translation.pipe';
import { ToastService, ToastType } from 'src/app/shared/service/toast.service';
import { TranslationService } from 'src/app/shared/service/translation.service';
import { environment } from 'src/environments/environment';
import { CircuitsServicesService } from '../../services/circuits-services.service';
import { LocationsHttpService } from '../../services/locations-http.service';
import { AppConstants } from 'src/app/shared/constants/constants';
import { LOCATIONS_CONSTANTS } from '../../interfaces/locations-constants';
declare let require: any;
let polyline = require("google-polyline");

@Component({
  selector: 'app-edit-circuit-details',
  templateUrl: './edit-circuit-details.component.html',
  styleUrls: ['./edit-circuit-details.component.scss']
})
export class EditCircuitDetailsComponent implements OnInit, OnDestroy {

  circuitName:any;
  trackDetailsName = '';
  urls: any;
  trackName:any;
  primaryButtonTitle:any = LOCATIONS_CONSTANTS.BUTTON_NAMES.SAVE_AND_UPDATE;
  secondaryButtonTitle:any = AppConstants.COMMON_BUTTON_LABELS.CANCEL;;
  primaryButtonColor:any = "primary";
  apiKey = environment.apikey;
  circuitInfo: any;
  isShowCircuitInfo: any = false;
  isShowTrackInfo : any = false
  paramGuid: any;
  circuitData: any;
  slides: any;
  circuitRadius: any;
  selectedMedia: any;
  mediaFiles: any = [];
  imageUrls: any = [];
  isShowFence: any = true;
  isInteractionEnabled: any = false;
  isZoomControlEnabled:any = false;
  showProgress = false;
  progress: number = 0;
  eventTotal: number = 100;
  showToast = false;
  toastMessage: any;
  toastType: any;
  position: any;
  leftPosition: any;
  coverImgUrl: any;
  showNotFoundPage: any;
  shareStatus: any;
  alertSubscription: any;
  isPrevPageLocation: any;
  isModalOpen: any = false;
  actionType: any;
  title:any;
  message:any;
  cancelBtnTitle:any;
  actionBtnTitle:any;
  showLayerSwitchMenu : boolean = false;
  browse:string=AppConstants.COMMON_BUTTON_LABELS.BROWSE
  placeholderObject = {
    placeholderTrackType : 'Circuit'
  }
  imageChangedEvent: any = '';
  croppedImage: any = '';
  containWithinAspectRatio:boolean = false;
  finalCroppedImage: any;
  backgroundColor:string = "#000";
  showSkeletonLoader: boolean = false;
  imageBase64String:any;
  imageName: any;
  imageSize: any;
  initialCoordinates: any;
  initialLocation: any;
  translationData: any;
  phrasePipe: any;
  buttonNames: any = AppConstants.COMMON_BUTTON_LABELS;
  locationConstants = LOCATIONS_CONSTANTS;

  @HostListener('window:beforeunload', ['$event'])
  showAlertMessageWhenClosingTab($event: any) {
    $event.returnValue = 'Your data will be lost!';
  }
    
  fileChangeEvent(event: any): void {
   
    if(this.mediaFiles.length > 0) {
      this.mediaFiles = [];
      this.locationsHttpService.clearMediaValue();
    }
    if(event.type === 'change') {
      this.imageName = event.srcElement.files[0].name;
      this.imageSize = event.srcElement.files[0].size;
      this.imageChangedEvent = event;
    } else {
      this.imageSize = event.size;
      this.convertToBase64ForCropper(event);      
    }    
  }

  convertToBase64ForCropper(event:any) {
    this.imageBase64String = '';
    let valid = this.checkFileValidation(event.name, event.size);
    if (valid) {
      this.imageName = event.name;
      const file = event;
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.imageBase64String = reader.result;
      };
    } else {
      this.loadImageFailed();
    }
    
}

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }

  imageLoaded() {
    let valid = this.checkFileValidation(this.imageName, this.imageSize);
    if (valid) {
      let modal = document.getElementById("cropModal");
      if (modal) {
        modal.style.display = "block";
      }
    }
    // show cropper
  }
  loadImageFailed(uploadedImgSize?: number) {
    // show message
    if (this.imageSize > AppConstants.MAX_IMAGE_SIZE || (uploadedImgSize && uploadedImgSize > AppConstants.MAX_IMAGE_SIZE)) {
      this.toastService.show(ToastType.Error, AppConstants.COMMON_ERROR_MSGS.MAX_ALLOWED_IMG_SIZE, '8%', '40%');
      setTimeout(() => {
        this.toastService.hide();
      }, AppConstants.MIN_TIME_TOASTER);
    } else {
      this.toastService.show(ToastType.Error, LOCATIONS_CONSTANTS.TOAST_ERROR_MSGS.ALLOWED_MEDIA_EXTENSION_MSG, '8%', '40%');
      setTimeout(() => {
        this.toastService.hide();
      }, AppConstants.MIN_TIME_TOASTER);
    }
  }

  closeModal() {
    let modal = document.getElementById("cropModal");
    if (modal) {
      modal.style.display = "none";
    }
  }

  loadCroppedImage() {
    this.finalCroppedImage = this.croppedImage;
    this.imageUrls = [];
    this.imageUrls.push(this.finalCroppedImage);
    const converToBlob = this.b64toBlob(this.finalCroppedImage);
    const convertedFile = new File([converToBlob], this.imageName, { type: 'image/jpeg' });
    if (!this.checkFileValidation(convertedFile.name, convertedFile.size)) {
      this.loadImageFailed(convertedFile.size);
      return;
    }
    this.mediaFiles = [];
    this.mediaFiles.push(convertedFile);
    this.locationsHttpService.sendMediaValue(this.mediaFiles);
    this.circuitData.media = {"image": [...this.imageUrls]};
    this.closeModal();
  }
  

  checkFileValidation(nameOfFile: any, size:any) {
    let isValidFiles = true;
    if(size > LOCATIONS_CONSTANTS.MAX_IMAGE_SIZE) {
      isValidFiles = false;
      this.showProgress = false;   
      return isValidFiles;
    }
    
      let fileExtension = nameOfFile.split(".");
      let extension = fileExtension[fileExtension.length - 1];
      if (!LOCATIONS_CONSTANTS.ALLOWED_EXTENTIONS_IMG_UPLOAD.includes(extension)) {
        this.showProgress = false;
        this.toastService.show(ToastType.Error, LOCATIONS_CONSTANTS.TOAST_ERROR_MSGS.ALLOWED_MEDIA_EXTENSION_MSG, '8%','40%');
        setTimeout(() => {
          this.toastService.hide();
        }, AppConstants.MIN_TIME_TOASTER);
        isValidFiles = false;
      }
    
    return isValidFiles;
  }

  b64toBlob(dataURI: any) {

    let byteString = atob(dataURI.split(',')[1]);
    let ab = new ArrayBuffer(byteString.length);
    let ia = new Uint8Array(ab);

    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: 'image/jpeg' });
  }
    

  addprogressBar() {
    setTimeout(() => {
      const progressInterval = setInterval(() => {
        this.progress += Math.round(this.eventTotal / 2);
        this.eventTotal /= 2;
        if (this.progress >= 100) {
          this.progress = 100;
          clearInterval(progressInterval);
        } else if (this.progress == 0) {
          this.addprogressBar();
        }
      }, 100);
    }, 100);
  }



  constructor(
    private route: ActivatedRoute,
    private locationsHttpService: LocationsHttpService,
    private router: Router,
    private toastService: ToastService,
    private location: Location,
    private circuitService: CircuitsServicesService,
    private translationService: TranslationService
    ) { 
    this.route.paramMap.subscribe((paramMap) => {
      let guid: any = paramMap.get("guid");
      this.paramGuid = guid;
    });
  }


  ngOnInit(): void {
    this.translationService.getTranslationDataAsObservable().subscribe(
      (translationData: any) => {
        this.translationData = translationData;
      }
    );
    this.phrasePipe = new CustomTranslationPipe;
    this.title=this.phrasePipe.transform(LOCATIONS_CONSTANTS.ALERT_POPUP_TITLE.CONFIRM_CANCEL, this.translationData)
    this.message="BP_LM_CONFIRM_SUBTITLE"
    // this.phrasePipe.transform("BP_LM_confirmModal_subtitle", this.translationData)
    this.cancelBtnTitle=this.buttonNames.NO;
    this.actionBtnTitle=this.buttonNames.YES;
    let urlData: any = this.location.getState();
    this.isPrevPageLocation = urlData?.isprevPagelocation;
    this.shareStatus = urlData?.shareable;
    this.toastService.toastDetails.subscribe((status: any) => {
      this.showToast = status.isShown;
      this.toastMessage = status.message;
      this.toastType = status.toastType;
      this.position = status.position;
      this.leftPosition = status.leftPosition;
    });

    this.initialCoordinates = this.circuitService.getEditedCoordinates();
    this.initialLocation = this.circuitService.getCoordinatesLocation();

        this.getCircuitDetailsData();
 
      this.setCircuitDetails();
  }


  getCircuitDetailsData() {
    // Shreya to check with Minnu, if this code is still required
    // const image = localStorage.getItem('form-image');
    if (!this.isPrevPageLocation) {
      let trackData = [];
      this.locationsHttpService
        .getCircuit(this.paramGuid)
        .subscribe(
          {
            next: (data) => {
          this.circuitData = data.body;
          this.circuitData.guid = this.paramGuid;
          if (this.circuitData.media?.image && this.circuitData.media?.image.length > 0) {
            this.showSkeletonLoader = true;
            this.imageUrls.push(this.circuitData.media?.image[0])
            // this.downloadBlobImage(this.circuitData.media.image[0].originalUrl)
          }
          if (this.circuitData.layouts && this.circuitData.layouts.length > 0) {
            this.circuitData.layouts.forEach((element: any) => {
              element.encodedGpsPoints = polyline.decode(element.encodedGpsPoints);
            });
          }
          if (this.circuitData.area) {
            this.circuitRadius = this.circuitData.area * 1000;
          }
          else {
            this.circuitRadius = polyline.decode(this.circuitData.encodedPolyline);
          }
        },
         error: (error: any) => {
            if (error.status === 406) {
              this.showNotFoundPage = true;
            }
          }
        }
          )
    } else {
      this.circuitData = {
        coordinates : this.circuitService.getEditedCoordinates(),
        location: this.circuitService.getCoordinatesLocation(),
        isPrevPageLocation: this.isPrevPageLocation
      }
      if(this.circuitData.coordinates.radius){
        this.circuitRadius = this.circuitData.coordinates.radius * 1000;
      } else {
        this.circuitRadius = polyline.decode(this.circuitData.coordinates);
      }
      console.log('this.circuitRadius',this.circuitRadius)  
    }
  }



  public setCircuitDetails(): void {
    // changing title name dynamically based on prev page flag (locations or details page)
    let prevPage:any = this.location.getState();
    this.circuitName = prevPage?.isprevPagelocation ? null :'Circuits'
    this.trackName = prevPage?.isprevPagelocation ? LOCATIONS_CONSTANTS.BUTTON_NAMES.ADD_DETAILS : 'BP_LM_EDIT_DETAILS'
    this.primaryButtonTitle = prevPage?.isprevPagelocation ? LOCATIONS_CONSTANTS.BUTTON_NAMES.SAVE_AND_PUBLISH : LOCATIONS_CONSTANTS.BUTTON_NAMES.SAVE_AND_UPDATE;
    this.trackDetailsName = prevPage?.isprevPagelocation ? '' : 'Edit details'
    this.urls = {
      circuitUrl:
      "/locations/circuit/"+ this.paramGuid,
      trackUrl:
        "",
      locationUrl: "/locations",
      isSelected: "track"
    };
  }


  deleteNewCircuit(_event: any) {
    if(this.isPrevPageLocation) {
      this.locationsHttpService
            .deleteCircuit(this.paramGuid)
            .subscribe({
              next: (_res: any) => {
                this.toastService.show(ToastType.Success, LOCATIONS_CONSTANTS.TOAST_SUCCESS_MESSAGES.CIRCUIT_DELETE_SUCCESS_MSG,'8%','41%');
                setTimeout(() => {
                  this.router.navigateByUrl("/locations");
                  this.toastService.hide();
                }, 4000);
              },
              error: (error: any) => {
                console.log(error);
                  const errorMsg = this.circuitService.getToastErrMessagesBasedOnCode(error);
                this.toastService.show(ToastType.Error, errorMsg, '1%', '40%');
                setTimeout(() => {
                  this.toastService.hide();
                  this.router.navigateByUrl("/locations");
                }, AppConstants.MIN_TIME_TOASTER);
              },
            });
    } else {
      this.title=LOCATIONS_CONSTANTS.ALERT_POPUP_TITLE.ALERT
      this.message=""
      this.cancelBtnTitle="No"
      this.actionBtnTitle="Yes"
      this.isModalOpen = true;
      this.actionType = "goToLocations";
    }   
  }


  deleteImage(imageUrls:any) {
    if(imageUrls) {
      this.locationsHttpService.sendDeleteMediaValue(imageUrls);
    } 
    this.imageUrls = [];
    this.mediaFiles = [];
    this.finalCroppedImage = null;
    localStorage.removeItem('form-image')
    this.finalCroppedImage = ''
    this.locationsHttpService.clearMediaValue();   
  }

 



  secondaryButtonClick() {
    this.title = this.phrasePipe.transform(LOCATIONS_CONSTANTS.ALERT_POPUP_TITLE.CONFIRM_CANCEL, this.translationData);
    this.message = "BP_LM_CONFIRM_SUBTITLE"
    this.cancelBtnTitle = this.buttonNames.NO;
    this.actionBtnTitle = this.buttonNames.YES;
    this.isModalOpen = true;
    this.actionType = "cancelEdit";
  }

  public goToCircuits(): void {
    this.title = LOCATIONS_CONSTANTS.ALERT_POPUP_TITLE.ALERT
    this.message = "BP_LM_CONFIRM_ALERT_MESSAGE"
    this.cancelBtnTitle = this.buttonNames.NO;
    this.actionBtnTitle = this.buttonNames.YES;
    this.isModalOpen = true;
    this.actionType = "goToCircuits";
  }

  getCancelModalResponse(event: any) {
    this.isModalOpen = false;
    if (event.action && event.actionType === "cancelEdit") {
      let prevPage: any = this.location.getState();
      if (prevPage.isprevPagelocation) {
          this.router.navigateByUrl("/locations");
      } else {
        this.router.navigateByUrl(
          "/locations/circuit/" + this.paramGuid
        );
      }
    } else if (event.action && event.actionType === "goToCircuits") {
      this.router.navigateByUrl(
        "/locations/circuit/" + this.paramGuid
      );
    } else if (event.action && event.actionType === "goToLocations") {
      this.router.navigateByUrl(
        "/locations"
      );
    }
  }  

  primaryButtonClick() {
    this.locationsHttpService.sendCircuitFormValue('save');
  }

  editGeoFence(){
    let mapData = { state: { apiKey:this.apiKey , location:this.circuitData?.location , radius:this.circuitRadius, track:this.circuitData?.layouts, name:this.circuitData.name, shareable: true, isPrevPageLocation:this.isPrevPageLocation } }
    localStorage.setItem('mapData', JSON.stringify(mapData))
    if(this.isPrevPageLocation){
      this.router.navigateByUrl('/locations/circuit/edit/geofence', mapData);
      this.circuitService.setCoordinateValue(this.initialCoordinates);
      this.circuitService.setCoordinatesLocation(this.initialLocation);
    } else {
    this.router.navigateByUrl(`/locations/circuit/${this.paramGuid}/edit/geofence`, mapData);
    }
  }


  resizeBase64Image(data:any) {
    // Get the base64-encoded image string
    const base64Image = data;

    // Create an Image object to load the image
    const img = new Image();

    // Set the src attribute to the base64-encoded image string
    img.src = base64Image;

    // Wait for the image to load
    img.onload = () => {
      // Create a canvas element
      const canvas = document.createElement('canvas');

      // Set the canvas dimensions to the desired size
      const maxWidth = 400;
      const maxHeight = 400;
      let width = img.width;
      let height = img.height;

      if (width > maxWidth) {
        height *= maxWidth / width;
        width = maxWidth;
      }

      if (height > maxHeight) {
        width *= maxHeight / height;
        height = maxHeight;
      }

      canvas.width = width;
      canvas.height = height;

      // Draw the image onto the canvas
      const ctx = canvas.getContext('2d');
      ctx?.drawImage(img, 0, 0, width, height);

      // Get the resized base64-encoded image string
      const resizedBase64Image = canvas.toDataURL('image/jpeg', 0.5);
      this.finalCroppedImage = resizedBase64Image;

      // Store the resized base64-encoded image string in local storage
      // localStorage.setItem('form-image', resizedBase64Image);
    };
  }

  ngOnDestroy() {
    if(this.finalCroppedImage){
      this.resizeBase64Image(this.finalCroppedImage);
    }  
  }


}