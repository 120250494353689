<div class="in-page-main">
  <ng-container *ngIf="!showNotFoundPage">
    <div *ngIf="showToast">
      <app-toast [message]="toastMessage" [toastType]="toastType" [position]="position"
        [leftPosition]="leftPosition" [placeholderObject]="placeholderObject"></app-toast>
    </div>
    <div *ngIf="isApiCallDispatched" class="spinner-border spinner" role="status"></div>
    <app-common-alert-modal [isModalOpen]=isModalOpen [title]="title" [actionType]="actionType"
      (isModalResponseClicked)="getCancelModalResponse($event)" [message]="message" [cancelBtnTitle]="cancelBtnTitle"
      [actionBtnTitle]="actionBtnTitle"></app-common-alert-modal>
    <div *ngIf="trackDetails">
      <app-layout-details-header [primaryButtonTitle]="primaryButtonTitle" [title]="trackName"
        [secondaryButtonTitle]="secondaryButtonTitle" [primaryButtonColor]="primaryButtonColor"
        (secondaryButtonClick)="secondaryButtonClick()" (primaryButtonClick)="primaryButtonClick()"
        [isDisablePrimaryButton]="isDisablePrimaryButton">
      </app-layout-details-header>
    </div>
    <div *ngIf="trackDetails" class="location-sub-details-inner">

      <div class="web-portal-container">
        <div class="sub-content map-layout-screen">
          <div class="lt-row-w-g">
            <div class="clm-4 clm-tab-4">
              <div class="map-content">
                <app-here-map *ngIf="encodedTrackPoints" #map [apiKey]="apiKey" [height]="mapHeight"
                  [trackInfo]="encodedTrackPoints" [sectorInfo]="encodedSectorPoints" [istrackDetailsPolyline]="true" [showLayerSwitchMenu]="false">
                </app-here-map>
                <img *ngIf="encodedTrackPoints" class="fullscreen-img" src="assets/locations/icn-expand.png" alt="..."
                  (click)="openFullScreenView()" />
              </div>

              <div class="download-zip">
                <span class="download_icon icon_zip"></span>
                <span class="track-name">{{ trackName }}.zip</span>
                <span (click)="downloadTrackDetails()" class="download_icon icon_download atn-btn"></span>
              </div>
              <!-- <div class="media-section">
                <app-media-upload [uploadEnable]="isEditClicked" [trackNameGuid]="this.paramTrackGuid"
                  [trackName]="trackName" [isShowGallery]="showGallery"></app-media-upload>
              </div> -->
              <div class="media-content">{{'BP_CM_ADD_COVER_IMG' | customTranslation:translationData}}</div>
              <div class="img-upload-container" appDragDropFileUpload (click)="bannerfileField.click()"
                (fileDropped)="onImageUpload($event)" *ngIf="!imageUrls.length">
                <div for="bannerfileField">
                  <span class="icon big upload-image"></span>
                </div>
                <div class="img-upload-text">
                  <input class="image-input-class" type="file" #bannerfileField (click)="bannerfileField.value = ''"
                    (change)="onImageUpload($event)" accept=".jpg, .jpeg, .png" />
                  <span>{{'BP_EM_DRAG_DROP' | customTranslation : translationData }} <strong
                      class="heightlight-color">{{'BP_CM_BROWSE_BTN_LBL' | customTranslation : translationData }}</strong>
                    {{'BP_CM_UPLOAD_COVER_IMAGE' | customTranslation : translationData }}</span>
                </div>
              </div>
              <div class="img-upload-container after-image" *ngIf="imageUrls?.length">
                <img [src]="imageUrls[0].thumbnailUrl ?? imageUrls | safe" class="image-preview" alt="..." />
                <div class="image-action">
                  <div class="change-image" (click)="bannerField.click()">
                    {{location_constants.HELPER_INPUT_LABELS.CHANGE_IMG| customTranslation:translationData}}
                  </div>
                  <div class="delete-image" (click)="onRemoveImage()">
                    {{'BP_LM_DELETE_COVER_IMAGE' | customTranslation:translationData}}
                  </div>
                  <input class="d-none" type="file" #bannerField (change)="onImageUpload($event)" accept=".jpg, .jpeg, .png" />
              
                </div>
                <!-- <input class="d-none" type="file" #bannerField (change)="onImageUpload($event)" accept=".jpg, .jpeg, .png" /> -->
              </div>
            </div>
            <div class="clm-8 clm-tab-4">
              <app-layout-details-form [formData]="trackDetails" [formEditable]="isEditClicked"
                [isFormDataReverted]="isFormDataReverted" (updatedTrackDetails)="updatedTrackDetails($event)"
                (setUpdateFlagValue)="setUpdateFlagValue($event)" 
                *ngIf="trackDetails" [showCopyrightData]="showCopyrightData" 
                [deletedImageIds] ="deletedImageIds" (isFormValid)="checkFormValidity($event)">
              </app-layout-details-form>
            </div>
          </div>
        </div>
      </div>

    </div>

  </ng-container>
</div>
<div *ngIf="showNotFoundPage">
  <app-page-not-found></app-page-not-found>
</div>