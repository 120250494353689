export const environment = {
  enableFeature:false,
  production: false,
  enableLocations: true,
  
  sso_api_username: "",

  sso_api_pwd: "",

  environment:'int',

  baseURL: "https://api.int.business.stories.me",

  authURL:
    "https://iam-auth.int.business.stories.me/oauth2/authorize?redirect_uri=https://int.business.stories.me/login&client_id=6llr3uqjbn09i8bn07v7kkuc5m&response_type=code&scope=email+openid&identity_provider=IAMProvider",

  loginURL:
    "https://iam-auth.int.business.stories.me/login?" +
    "client_id=6llr3uqjbn09i8bn07v7kkuc5m&response_type=code&scope=email+openid&identity_provider=IAMProvider&" +
    "redirect_uri=https://int.business.stories.me/login",

  redirectURL: "https://int.business.stories.me/login",

  cognitoTokenURL: "https://iam-auth.int.business.stories.me/oauth2/token",

  cognitoUserPoolClientId: "6llr3uqjbn09i8bn07v7kkuc5m",

  logout:
    "https://iam-auth.int.business.stories.me/logout?" +
    "client_id=6llr3uqjbn09i8bn07v7kkuc5m&" +
    "logout_uri=https://int.business.stories.me/admin-console/logout",

  apiEndPoint: "https://amgmaa-nonprod.mercedes-benz.com/aca",

  // apikey: "kywc-xPYsQlEKurbsfETfNjed-sERdo6uHRIuyeQI80"
  // apikey: "j4z2Zolw9H8nQkuZzZVlDGDBtyX5u0jPSAM-LEEdRdo",
  apikey: "ClDZ598lbJmMqyHWIgWtCrenBcT4WQX5PlY2cqipraY",

  proxyUrl: "https://proxy.int.stories.me",

  routeBaseURL: "https://int.stories.me",
  phraseEndPoint: 'https://api.phrase.com/v2/projects',
  phraseUrl: 'https://api.phrase.com/v2/projects/b839b613fcfd932815f81fde21590082/locales/@languageId/translations?access_token=12d65253353cc19d0a84bed6af5cb1d64b20df3121313110d7a0c4159c320dbe', 


  RUM_CONFIG_SESSION_SAMPLE_RATE: 1.0,
  RUM_GUEST_ROLE_ARN: "arn:aws:iam::481005727943:role/Infrastructure-RUMCognito-RumIdentityPoolUnauthent-10S6JSOJD0O0C",
  RUM_CONFIG_IDENTITY_POOL_ID: "eu-central-1:27c70001-4374-4e71-9b7c-33fa856a8593",
  RUM_ENDPOINT: "https://dataplane.rum.eu-central-1.amazonaws.com",
  RUM_CONFIG_TELEMETRIES:  ["errors", "performance", "http"],
  RUM_CONFIG_ALLOW_COOKIES: true,
  RUM_CONFIG_ENABLE_XRAY: true,
  RUM_APPLICATION_ID: "8d91d636-396c-4f13-8043-a9c4afe8c872",
  RUM_APPLICATION_REGION: "eu-central-1",

  supported_language:['en', 'de', 'en-GB', 'de-DE', 'es-ES', 'fr-FR', 'ko-KR', 'cs-CZ'],
  // amplifyConfig: {
  //   region: 'eu-central-1',
  //   userPoolId: 'eu-central-1_qvIXvxCmJ',
  //   userPoolWebClientId: '6llr3uqjbn09i8bn07v7kkuc5m',
  //   oauth: {
  //     domain: 'iam-auth.int.business.stories.me',
  //     scope: ['email', 'openid'],
  //     redirectSignIn: 'https://int.business.stories.me/login',
  //     redirectSignOut: 'https://sso-int.mercedes-benz.com/idp/startSLO.ping?TargetResource=https%3A%2F%2Fint.business.stories.me%2Flogout',
  //     responseType: 'code',
  //   },
  // }
};
