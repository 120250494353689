<div id="carousel-region">
  <div #myDiv class="image-card-carousel">
    <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig"
      [class.scroll-slide]="isShowPrevArrow" (beforeChange)="beforeChange($event)">
      <div class="carousel-card" ngxSlickItem *ngFor="let slide of trackData">
        <div class="main-class"
          *ngIf="slide.properties.media?.thumb_url || slide.properties.description || slide.properties.name || slide.properties.route_position"
          [ngClass]="(slide.id === selectedAnnotationId && this.isTrackSelectionCount) ? 'active-track':''">
          <div class="image-region-main">
            <div class="image-item" *ngFor="let trackmedia of slide.properties.media">
              <ng-container *ngIf="trackmedia.thumb_url">
                <div class="cover-image" (click)="showTrackPolyline(slide,'fromImageCardClick')" [ngClass]="!slide.properties.description && (slide.properties.name || slide.properties.route_position)  ? 'image-content-bottom' :''">
                  <img class="media-thumbnail" [src]="trackmedia.thumb_url" alt=" "/>
                  <div class="liner-gradient" [ngClass]="!slide.properties.description && (slide.properties.name || slide.properties.route_position)  ? 'linear-content-bottom' :''">
                </div>
              
                </div>
              </ng-container>
            </div>
          </div>
          <div class="emotiontour-details emotion-content-bottom" (click)="showTrackPolyline(slide,'fromImageCardClick')">
            <div class="emotion-tour-name" *ngIf="slide.properties.name">
              <span>{{ slide.properties.name}}</span>
            </div>
            <div class="route-position" *ngIf="slide.properties.route_position">
              <span>in {{slide.properties.route_position |number: '1.2-2'}} km</span>
            </div>
          </div>
          <div class="description" (click)="showTrackPolyline(slide,'fromImageCardClick')"
            *ngIf="slide.properties.description && (slide.id === selectedAnnotationId && this.isTrackSelectionCount)">
            <span>{{slide.properties.description}}</span>
          </div>
        </div>
      </div>
    </ngx-slick-carousel>
    <button *ngIf="trackData?.length >= 8 && isShowNextArrow" (click)="slickModal.slickNext()" class="cr-arrow right-arrow">
      <img src="assets/locations/right-nav.png" alt=" "/>
    </button>
    <button *ngIf="isShowPrevArrow" (click)="slickModal.slickPrev()" class="cr-arrow left-arrow">
      <img src="assets/locations/left-nav.png" alt=" "/>
    </button>
    <div *ngIf="trackData?.length == 0" class="empty-card">
      <div class="carousel-card">
        <div class="carousel-card-content">
          <div>
            <span>No annotations available</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>