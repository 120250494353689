import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Router } from "@angular/router";
import { ToastService, ToastType } from "src/app/shared/service/toast.service";
import { TranslationService } from "src/app/shared/service/translation.service";
import { environment } from "src/environments/environment";
import { LocationsHttpService } from "../../services/locations-http.service";
import { AlertModalService } from "../../services/modal/alert-modal.service";
import { LanguageService } from "src/app/shared/service/language.service";
import { AppConstants } from "src/app/shared/constants/constants";
import { LOCATIONS_CONSTANTS } from "../../interfaces/locations-constants";
import { CircuitsServicesService } from "../../services/circuits-services.service";

@Component({
  selector: "app-emotion-tour-modal",
  templateUrl: "./emotion-tour-modal.component.html",
  styleUrls: ["./emotion-tour-modal.component.scss"],
})
export class EmotionTourModalComponent implements OnInit {
  @Input() public emotionTourDetails: any;
  title: any;
  owner: any;
  trackLength: any = "";
  startPoint: any;
  endPoint: any;
  layoutURL: string = "";
  navigateUrl = "";
  navigateMessage = "";
  createdDate = new Date();
  @Output() public isShowMapView = new EventEmitter<object>();
  public isshowDropDown:any=true;
  isMenuDropdownVisible = false;
  isDeleteSelected=false;
  isEditSelected=false;

  isApiCallDispatched = false;

  alertTitle:any;
  alertMessage:any;
  isModalOpen:any;
  actionType:any;
  alertCancelBtnTitle:any;
  alertActionBtnTitle:any;
  translationData: any;
  language: string = 'en';
  buttonNames: any = AppConstants.COMMON_BUTTON_LABELS

  constructor(
    private locationsHttpService: LocationsHttpService,
    private alertModalService: AlertModalService,
    private toastService: ToastService,
    private router: Router,
    private translationService: TranslationService,
    private languageService: LanguageService,
    private _circuitService: CircuitsServicesService
    ) {}

  ngOnInit(): void {
    this.buttonNames = AppConstants.COMMON_BUTTON_LABELS;
    this.language = this.languageService.getLanguage();
    this.translationService.getTranslationDataAsObservable().subscribe(
      (translationData: any) => {
        this.translationData = translationData;
      }
    );
    if (this.emotionTourDetails) {
      this.navigateMessage = "BP_LM_BACK_TO_LOCATIONS_BTN_LBL";
      this.title = this.emotionTourDetails.properties.name;
      this.owner = this.emotionTourDetails.properties.provider;
        
      this.emotionTourDetails.properties.createdDate = new Date(
        this.emotionTourDetails.properties.createdDate.replace(/\s/, 'T')
      ).toLocaleDateString("de-DE", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      });
      this.trackLength = this.emotionTourDetails.properties.trackLength / 1000;
    }
  }

  getCancelModalResponse(event:any){  
    this.isEditSelected=false;
    this.isDeleteSelected=false;
    this.isModalOpen = false;  
      if (event.action && event.actionType == "delete-emotiontour") {
        if(this.emotionTourDetails?.guid){
        this.isApiCallDispatched = true;
        this.locationsHttpService
          .deleteTrack(this.emotionTourDetails.guid)
          .subscribe({
            next: () => {
              this.toastService.show(ToastType.Success, LOCATIONS_CONSTANTS.TOAST_SUCCESS_MESSAGES.TRACK_DELETE_SUCCESS_MSG, '8%','45%');
              setTimeout(() => {
                this.toastService.hide();
              }, AppConstants.MIN_TIME_TOASTER);
              setTimeout(() => {
                this.router.navigateByUrl('/locations')
                
              }, AppConstants.MIN_TIME_TOASTER);
              this.alertModalService.onAction.next(null);
              this.isApiCallDispatched = false;
              this.isMenuDropdownVisible=false;
            },
            error: (error: any) => {
              const errorMsg = this._circuitService.getToastErrMessagesBasedOnCode(error);
              this.toastService.show(ToastType.Error, errorMsg, '1%', '40%');
              setTimeout(() => {
                this.toastService.hide();
              }, AppConstants.MIN_TIME_TOASTER);
              this.alertModalService.onAction.next(null);
              this.isApiCallDispatched = false;
              this.isMenuDropdownVisible = false;
            },
          });
        }
      }
  // });
  }

  goToDetailsUrl() {
    let emotionTourData = JSON.stringify({
      name: this.emotionTourDetails.properties.name,
      guid: this.emotionTourDetails.guid,
    });
    this.emotionTourDetails.properties.name = this.emotionTourDetails.properties.name
      .replace(/\s+/g, "")
      .replace(/[^\w\s]/gi, "");
    
    this.layoutURL = `${environment.routeBaseURL}/${this.language}/explore/emotion-tour/${this.emotionTourDetails.properties.name}-${this.emotionTourDetails.guid}`;
    // Store the return of the `open` command in a variable
    let newWindow: any = window.open(this.layoutURL, "_blank");

    // Access it using its variable
    if (newWindow) {
      newWindow.emotionTourData = emotionTourData;
    }
  }
  downloadTrackDetails() {
    this.locationsHttpService
      .downloadTrackDetails(this.emotionTourDetails.guid)
      .subscribe((data: any) => {
        const blob = new Blob([data], {
          type: "application/zip",
        });
        const url = window.URL.createObjectURL(blob);
        const anchor = document.createElement("a");
        anchor.download = this.emotionTourDetails.properties.name;
        anchor.href = url;
        anchor.click();
      });
  }
  showSearch(search: any) {
    this.isShowMapView.emit({ showSearch: search.showSearch });
  }

  dropdownFixPosition(event:any) {
    this.isMenuDropdownVisible = !this.isMenuDropdownVisible;
    if(!this.isshowDropDown && !this.isMenuDropdownVisible){
    this.isshowDropDown=!this.isshowDropDown
    this.isMenuDropdownVisible = !this.isMenuDropdownVisible;
    }
    event.stopPropagation();
  }

  hideDropDown(){
    if(this.isshowDropDown && this.isMenuDropdownVisible){
      this.isshowDropDown=!this.isshowDropDown;
    }
  }

  deleteEmotionTour() {
    this.isDeleteSelected=true;
    this.isEditSelected=false;
    this.isModalOpen = true;
    this.alertTitle = "Confirm Delete "
    this.alertMessage = "Are you sure you want to delete this emotion tour?"
    this.actionType = "delete-emotiontour"
    this.alertCancelBtnTitle = AppConstants.COMMON_BUTTON_LABELS.CANCEL;
    this.alertActionBtnTitle = AppConstants.COMMON_BUTTON_LABELS.DELETE;
  }

}