<ng-container *ngIf="selectedUserType === 'you'">
<ng-container *ngIf="tracks.length > 0" class="your-tracks-style">
    <div *ngIf="selectedUserType === 'all'" class="your-tracks-style">Results ({{tracks.length}})</div> 
    <div *ngIf="selectedUserType === 'you'" class="your-tracks-style">Your Created ({{tracks.length}})</div> 
</ng-container>
<div>
    <div *ngFor="let item of tracks" class="overlay-image-container">
        <div (click)="goToTrack(item)" class="overlay-image">
            <div class="overlay-text">{{item.name}}
                <p> 
                    <span *ngIf="item.type.toLowerCase() === 'emotion_tour'" class="blue-tag">Emotion Tour</span> 
                    <span *ngIf="item.type.toLowerCase() === 'circuit'" class="red-tag">Circuit</span>
                    <span *ngIf="item.type.toLowerCase() === 'emotion_tour' && item.length" class="black-tag">{{(item.length / 1000).toFixed(2)}}Km</span> 
                    <span *ngIf="item.type.toLowerCase() === 'emotion_tour' && item.numberOfAnnotations" class="black-tag">{{item.numberOfAnnotations}} highlights</span>
                    <span *ngIf="item.type.toLowerCase() === 'circuit'" class="black-tag">{{item.totalNumberOfLayouts}} Layouts</span>
                </p>
            </div>            
         </div>
        <img *ngIf="item.type.toLowerCase() === 'emotion_tour' && item.imageUrl" class="img-style" [src]="item.imageUrl" alt="..."/>
        <img *ngIf="item.type.toLowerCase() === 'circuit' && item.imageUrl" class="img-style"  [src]="item.imageUrl | safe" alt="..." /> 
        <img *ngIf="item.type.toLowerCase() === 'emotion_tour' && !item.imageUrl" class="img-style" src="assets/locations/blueemotion.png" alt="..."/>
        <img *ngIf="item.type.toLowerCase() === 'circuit' && !item.imageUrl" class="img-style" src="assets/locations/redcircuit.png" alt="..."/>   
    </div>
</div>
</ng-container>
