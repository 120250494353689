<ng-container *ngIf="((!displayPublishedDetails && !this.tourPublishedView) || editAfterPublishActive) && 
((isOffroadTourFlow && tourInfoPostPublish?.guid) || !isOffroadTourFlow)">
    <div class="modal-main-class"
        [ngClass]="{'move-form-block-down': hideFormData, 'no-add-more-details-block': (!addMoreDetailsFlag && (currentStep === 'THIRD' || currentStep === 'ADD_WAY_POINT_DETAILS')), 'add-more-details-block': ((addMoreDetailsFlag && (currentStep === 'THIRD' || currentStep === 'ADD_WAY_POINT_DETAILS'))|| editHighlightPin), 'destination-block-height': (startLocationButtonClickedFlag && (currentStep === 'SECOND' || currentStep === 'ADD_WAY_POINT') || (currentStep === 'START_ADDED' || currentStep === 'DESTINATION_ADDED')), 'tour-form-block-height': (currentStep === 'WAY_POINT_ADDED' || currentStep === 'DESTINATION_CANCEL'), 'tour-form-block-height-without-highlight': currentStep === 'DESTINATION_CANCEL' && highlightCount === 0 }">
        <!-----------Modal Header and Cancel button section start here---------->
        <div [ngClass]="showSearchResult() ? 'modal-class-search': 'modal-class-main'">
            <div class="widget-section" *ngIf="!viewSelectedCardDetails; else elseBlock">
                <div class="widget-title-bar">
                    <h2>
                        <span>{{ getStepwiseModalTitle() | customTranslation: translationData }}</span>
                    </h2>
                    <button class="trans" (click)="handleStepwiseCancelFlow()" *ngIf="!tourPublished || this.editAfterPublishActive || 
                    (tourPublished && tourInfoPostPublish?.guid)">
                        <span>{{buttonNames.CANCEL | customTranslation:translationData }}</span>
                    </button>
                </div>
            </div>
            <!-----------Modal Header and Cancel button section end here, reuse for Post Publish---------->
            <!----------View Details Screen Start Here with Name and description---------------->
            <ng-template #elseBlock>
                <div>
                    <div class="overlay-image" *ngIf="selectedLocation?.media_details?.length; else noImageBlock">
                        <div class="img-section">
                            <figure>
                                <img [src]="selectedLocation?.media_details[0]?.thumb_url" alt="...">
                            </figure>
                            <button class="back_btn" type="button" (click)="hideSelectedCardDetails()"><span class="icon icon_back_arrow"></span><span>{{locationButtonNames.BACK | customTranslation: translationData }}</span></button>
                        </div>
                    </div>
                    <ng-template #noImageBlock>
                        <div>
                        <button class="back-btn-no-img" type="button" (click)="hideSelectedCardDetails()"><span class="icon icon_back_arrow"></span><span>{{locationButtonNames.BACK  | customTranslation: translationData}}</span></button>
                        </div>
                    </ng-template>
                    <div class="view-details" *ngIf="viewSelectedCardDetails">
                        <div class="gradient-section">
                            <div class="panel-row">
                                <ng-container *ngIf="selectedLocation.index === 'start'; else destinationBlock">
                                    <span class="icon icon_start_flag" style="font-size: 1.5em;"></span><p class="loc-title" title="{{selectedLocation?.title}}"><span>| {{(selectedLocation?.title ? selectedLocation?.title: selectedLocation?.name) | shortenText: 19}}</span></p>
                                </ng-container>
                                <ng-template #destinationBlock>
                                    <ng-container *ngIf="selectedLocation.index === 'destination' else wayPointBlock">
                                        <span class="icon icon_dest_flag" style="font-size: 1.5em;"></span><p class="loc-title" title="{{selectedLocation?.title}}"><span>| {{(selectedLocation?.title ? selectedLocation?.title: selectedLocation?.name) | shortenText: 19}}</span></p>
                                    </ng-container>
                                </ng-template>
                                <ng-template #wayPointBlock>
                                    <p class="loc-title" title="{{selectedLocation?.title ? selectedLocation?.title: selectedLocation?.name}}">
                                        <span>{{selectedLocation.index + 1}}</span><span> | {{(selectedLocation?.title ? selectedLocation?.title:
                                            selectedLocation?.name) | shortenText: 20}}
                                        </span>
                                    </p>
                                </ng-template>
                            </div>
                            <span class="panel-row">
                                <small>{{selectedLocation?.name}}</small>
                            </span>
                            <p class="loc-details">
                                {{selectedLocation?.description ? selectedLocation?.description: ''}}
                            </p>
                        </div>
                    </div>
                </div>
            </ng-template>
         <!----------View Details Screen End Here with Name and description---------------->
            <!------------Search Section Start Here----------------->
            <ng-container *ngIf="showSearchResult()">
                <div class="widget-section">
                    <div class="search-reg-main">
                        <app-search placeholderText="BP_LM_SEARCH_LBL" [page]="newpage"
                            (showSearchedLocation)="emitChangeInput($event)" [selectedLocationName]="selectedLocation?.name" (searchCancelledForEt)="searchCancelledEmiiter($event)">
                        </app-search>
                    </div>
                </div>
            </ng-container>
            <!---------------------Search section end here---------------------------->
            <!----------------------Code Changes for creating AMG annotation start here--------------------->
            <ng-container *ngIf="currentStep === 'ADD_WAY_POINT_DETAILS' || currentStep === 'THIRD' || currentStep === 'EDIT_START' || currentStep === 'EDIT_DESTINATION'">
                <div class="widget-section">
                    <form [formGroup]="addRouteForm">
                        <div class="form-container-scrollable"
                            [ngClass]="{'add-more-details-scroll': (addMoreDetailsFlag || editHighlightPin) && (currentStep === 'ADD_WAY_POINT_DETAILS' || currentStep === 'THIRD' || currentStep === 'EDIT_START' || currentStep === 'EDIT_DESTINATION')}">
                            <div class="form-container"
                                [ngClass]="{'selected-location-scroll': !addMoreDetailsFlag && currentStep === 'ADD_WAY_POINT_DETAILS'}">
                                <ng-container
                                    *ngIf="(addMoreDetailsFlag && (currentStep === 'ADD_WAY_POINT_DETAILS' || currentStep === 'THIRD' || currentStep === 'EDIT_START' || currentStep === 'EDIT_DESTINATION')) || editHighlightPin">
                                    <div class="form-row">
                                        <div class="form-item material full-w">
                                            <textarea maxlength="50" type="text" autocomplete="off"
                                                class="form-control"
                                                [ngClass]="{'content-available': addRouteForm.get('annotationName')?.value}"
                                                formControlName="annotationName"                                                
                                                style="resize: none;">
                                            </textarea>
                                            <label> {{'BP_LM_RENAME_ADD_LBL' | customTranslation: translationData }}</label>
                                        </div>
                                        <div class="form-note-text right">
                                            <span class="word-limit">{{addRouteForm.get('annotationName')?.value?.length}} / 50
                                                {{commonLabels.CHARACTERS
                                                | customTranslation: translationData }}</span>
                                        </div>
                                    </div>
                                    <div class="form-row">
                                        <div class="form-item material full-w">
                                            <textarea [ngClass]="{'content-available': addRouteForm.get('annotationDesc')?.value}"
                                                maxlength="500" type="text" autocomplete="off" class="form-control"
                                                id="floatingInput" placeholder="" formControlName="annotationDesc" 
                                            ></textarea>
                                            <label> {{ commonLabels.DESCRIPTION | customTranslation: translationData }}<span *ngIf="markFieldsAsRequired">*</span>
                                            </label>
                                        </div>
                                        <div class="form-note-text right">
                                            <span class="word-limit">{{addRouteForm.get('annotationDesc')?.value?.length}} / 500 {{commonLabels.CHARACTERS
                                                | customTranslation: translationData }}</span>
                                        </div>
                                    </div>
                                    <div class="form-row">                                       
                                        <app-common-media-upload [markMediaFieldsAsRequired]="markFieldsAsRequired" (mediaFilesUpdated)="updatingMediaFiles($event)" 
                                        [currentStep]="currentStep" 
                                        [saveImgWithCopyright]="showCopyrightDialogBoxWithSavedInfo.saveImgWithCopyright"></app-common-media-upload>
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                    </form>
                </div>
            </ng-container>
            <!----------------------Code Changes for creating AMG annotation end here--------------------->
                        <!---------------------------------Generate Tour Step Changes Start here----------------------->
                        <ng-container
                        *ngIf="(currentStep === 'WAY_POINT_ADDED' || currentStep === 'DESTINATION_ADDED' || currentStep === 'EDIT_AFTER_PUBLISH') && !viewSelectedCardDetails">
                        <div class="widget-section">
                            <div class="form-container">
                                <div class="tour-details-style">
                                    <form #tourForm="ngForm" [ngClass]="{'hide': hideFormData}">
                                        <div class="add-location">
                                            <p>
                                                <span [innerHTML]="'BP_LM_CREATING_TRK_UNDER_LBL' | customTranslation: {'{placeholderTrackType}': trackType | customTranslation: translationData, '{placeholderGroupName}': currentUserProfile}: translationData">
                                                </span>
                                            </p>
                                        </div>
                                        <div class="tour-form-scroll-container">
                                            <div class="form-row">
                    
                                                <div class="form-item material full-w">
                                                    <input [ngClass]="{'not-empty': tourName.length > 0}" type="text" maxlength="50"
                                                        autocomplete="off" [(ngModel)]="tourName" name="tourName" class="tour-name"
                                                        required />
                                                    <label *ngIf="!hideFormData"
                                                        [ngClass]="languageSelected === 'de' ? 'ger-lbl': 'eng-lbl'">
                                                        {{'BP_LM_ENTER_TOUR_NAME' | customTranslation:translationData}} *
                                                    </label>
                                                </div>
                                                <div class="form-note-text right">
                                                    <span class="word-limit">{{tourName.length}} / 50 {{commonLabels.CHARACTERS
                                                        | customTranslation: translationData }}</span>
                                                </div>
                                            </div>
                                            <div class="form-row">
                                                <div class="form-item material full-w">
                                                    <textarea
                                                        [ngClass]="{'content-available': tourDesc.length > 0, 'change-textarea-style': textareaFocusIn()}"
                                                        maxlength="500" type="text" autocomplete="off" [(ngModel)]="tourDesc"
                                                        class="form-control" name="tourDesc" (focus)="textareaFocusIn();"
                                                        style="resize: none;"></textarea>
                                                    <label class="tour-desc-label" *ngIf="!hideFormData"> {{commonLabels.DESCRIPTION | customTranslation:translationData}}</label>
                                                </div>
                                                <div class="form-note-text right">
                                                    <span class="word-limit">{{tourDesc.length}} / 500 {{commonLabels.CHARACTERS
                                                        | customTranslation: translationData }}</span>
                                                </div>
                    
                                            </div>
                                            <div class="form-row">
                                                <app-common-media-upload [tourCoverImg]="coverImg" [currentStep]="currentStep"
                                                    [selectedLocation]="selectedLocation"
                                                    [saveImgWithCopyright]="showCopyrightDialogBoxWithSavedInfo.saveImgWithCopyright"></app-common-media-upload>
                                            </div>
                                        </div>
                                    </form>
                    
                                    <div *ngIf="hideFormData" class="loader-container">
                                        <div class="spinner-border spinner loader"></div>
                                    </div>
                    
                                </div>
                            </div>
                        </div>
                    </ng-container>
                    <!---------------------------------Generate Tour Step Changes End here----------------------->
            <!-------------Common button section-------------------->
            <div class="widget-section">
                <div class="button-region"*ngIf="(currentStep !== 'ADD_WAY_POINT')" [ngClass]="(tourPublished && (currentStep !== 'ADD_WAY_POINT' && currentStep !== 'ADD_WAY_POINT_DETAILS')) || (editActive && addMoreDetailsFlag) ? '': 'space-between'">
                    <button (click)="decidePrimBtnActnOnClk()" class="beta" [disabled]="getDisabledPrimaryBtn()"
                        *ngIf="showPrimaryBtn()">
                        <span>{{ getPrimaryButtonName() | customTranslation: translationData }}</span>
                    </button>
                    <button type="button" [ngClass]="editActive && addMoreDetailsFlag ? 'hallow': 'trans'" (click)="decideSecBtnActnOnClk('delete')"
                        [disabled]="getDisabledSecondatyBtn()"
                        *ngIf="showSecondaryBtnRight()">
                        <span>{{ getSecondaryButtonName() | customTranslation: translationData }}</span>
                        <span (mouseover)="hovered = true" (mouseout)="hovered = false"
                            class="icon icon_info_round" *ngIf="!editActive && !tourInfoPostPublish" appTooltip
                            [tooltip]="'BP_LM_AMG_INFO_MSG'" position="right" [anchorPos]="'middle'"></span>
                    </button>
                    <!--------------This is within Add details screen----------->
                    <span (mouseover)="hovered = true" class="icon icon_info_round"
                        [ngClass]="editActive ? 'info-icon-display': ''"
                        *ngIf="(currentStep === 'ADD_WAY_POINT_DETAILS' || currentStep === 'THIRD' || currentStep === 'EDIT_START' || currentStep === 'EDIT_DESTINATION') && addMoreDetailsFlag" appTooltip
                        [tooltip]="'BP_LM_AMG_INFO_MSG'" position="right" [anchorPos]="'middle'"></span>
                </div>
            </div>
        </div>
    </div>
</ng-container>

<!-- GPX upload starts here -->
<ng-container *ngIf="(currentStep === 'SECOND' || currentStep === 'START_ADDED') && !tourPublishedView">
    <div class="gpx-file-block" *ngIf="!startLocationButtonClickedFlag">
        <div class="panel-box">
            <div class="panel-row">
                <span>{{'BP_LM_GPX_MSG' | customTranslation: translationData}}</span>
            </div>
            <div class="panel-row">
                <div class="button-region full-w file-upload-block">
                    <input type="file" id="gpx-upload" #gpxFileUpload (change)="startTrackUploadOnFileSelect($event)" hidden
                        accept=".gpx" (click)="gpxFileUpload.value = ''" />
                    <label for="gpx-upload">
                        <span class="icon icon_upload"></span>
                        <span>{{buttonNames.GPX_FILE_UP | customTranslation: translationData}}</span>
                    </label>
                    <button class="hallow full-w" (click)="handleStepwiseCancelFlow()" *ngIf="isOffroadTourFlow">
                        <span>{{buttonNames.CANCEL | customTranslation: translationData}}</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</ng-container>
<!-- GPX upload Ends here -->

<!----------------------- Changes for Round Trip start here ----------------------------------->
<ng-container *ngIf="isRoundTripAvailable && (startLocationButtonClickedFlag && (currentStep === 'START_ADDED' || currentStep === 'THIRD'))">
    <div class="gpx-file-block">
        <div class="panel-box">
            <div class="panel-row">
                <span>{{helperMessages.CREATE_ROUND_TRIP_MSG| customTranslation: translationData}}</span>
            </div>
            <div class="panel-row">
                <div class="button-region full-w">
                    <button (click)="changeToRoundTripOnClk()" class="beta full-w">
                        <span>{{'BP_LM_CREATE_BTN_LBL' | customTranslation: translationData}}</span>
                        <span class="icon icon_fwd_arrow"></span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</ng-container>

<!---------------------------------------Code for Post Tour Creation-------------------------------------------->
<ng-container *ngIf="((displayPublishedDetails || tourPublishedView) && !editAfterPublishActive)">
    <div class="business-modal-style section">
        <div class="business-detail-main" (click)="toggleDropdownMenuOptions('hide'); initiateGpxDownloadFlow('hide')" *ngIf="!viewSelectedCardDetails; else selctedCardSection">
            <div class="overlay-image">
                <div class="img-section">
                    <ng-container>
                        <img [src]="coverImg | safe" alt="..." *ngIf="coverImg" /> 
                        <button class="back_btn" type="button" (click)="goBackToLocations()"><span class="icon icon_back_arrow"> </span><span>{{buttonNames.BACK | customTranslation: translationData}}</span></button>
                    </ng-container>
                </div>
                <div class="gradient-section" *ngIf="tourInfoPostPublish?.nickName && tourInfoPostPublish?.createdDate">
                    <div class="name-section">
                        <span class="user-name-label" [innerHTML]="'BP_LM_CREATED_TOUR_LBL' | customTranslation: {'{placeholderName}': tourInfoPostPublish?.nickName,
                            '{placeholderDate}': tourInfoPostPublish?.createdDate}: translationData">
                        </span>
                    </div>
                    <div class="tour-title-reg">
                        <span class="title">{{tourName ? tourName: tourInfoPostPublish?.tourName}}</span>
                        <span class="menu-container">
                            <span class="icon icon_kebab" data-toggle="dropdown" 
                            appClickOutside (clickOutside)="toggleDropdownMenuOptions('hide'); initiateGpxDownloadFlow('hide')"
                                [ngClass]="isMenuDropdownVisible? 'active-menu':'menu'"
                                (click)="toggleDropdownMenuOptions('show', $event)" #menuLink>
                                <div [style.display]="isMenuDropdownVisible ? 'block' : 'none'"
                                    class="dropdown kebab-dropdown dropdown-sm" #dropdown>
                                    <div class="dropdown-menu dropdown-menu-right">
                                        <button (click)="changeStepToEdit()" *ngIf="enableEditPublishedTour"
                                            [ngClass]="isDeleteSelected ? 'selected-menu':''"
                                            class="dlt-btn cursor-pointer">
                                            <span>{{buttonNames.EDIT | customTranslation: translationData}}</span> 
                                        </button>
                                        <button (click)="showConfirmDeleteDialog()"
                                            [ngClass]="isDeleteSelected ? 'selected-menu':''"
                                            class="dlt-btn cursor-pointer">
                                            <span>{{buttonNames.DELETE | customTranslation:  translationData }} </span> 
                                        </button>
                                    </div>
                                </div>
                            </span>
                        </span>
                    </div>
                </div>
            </div>
            <div class="flex-container" [hidden]="!tourDesc && !tourInfoPostPublish?.tourDescription">
                <div class="tour-dist-info">
                    <span class="label-text">{{commonLabels.DESCRIPTION | customTranslation: translationData }}</span>
                    <span class="description">{{tourDesc ? tourDesc: tourInfoPostPublish?.tourDescription}}</span>
                </div>
            </div>
            <div class="bottom-section" *ngIf="!viewSelectedCardDetails">
                <div class="button-region">
                    <button class="beta" (click)="viewPublicVersion()" *ngIf="!isOffroadTourFlow"
                        [disabled]="!tourInfoPostPublish?.provider && !tourInfoPostPublish?.createdDate">
                        <span>{{locationButtonNames.VIEW_SUMMARY  | customTranslation: translationData}}</span>
                    </button>
                    <button data-toggle="dropdown" class="hallow" #gpxButton
                        [disabled]="!tourInfoPostPublish?.provider && !tourInfoPostPublish?.createdDate"
                        (click)="initiateGpxDownloadFlow(availableTrackVersions.length > 1 ? 'show': 'download', $event)">
                        <ng-template #downloadBlock><span class="icon icon_download"></span></ng-template>
                        <span data-toggle="dropdown" [ngClass]="displayGpxMenu? 'active-menu':''">{{buttonNames.GPX_FILE_UP |
                            customTranslation: translationData }} </span>
                        <span *ngIf="availableTrackVersions.length > 1; else downloadBlock" [ngClass]="!displayGpxMenu ? 'icon icon_down_arrow': 'icon icon_up_arrow'" style="font-size: 0.75em;">
                        </span>
                    </button>
                    <button class="hallow" (click)="showAudioUploadDialog(true)" *ngIf="!isOffroadTourFlow"
                        [disabled]="!tourInfoPostPublish?.provider && !tourInfoPostPublish?.createdDate">
                        <span class="icon icon_audio_upload"> </span>
                    </button>
                </div>
            </div>
            <div class="gpx-drop-down-section" [ngStyle]="{ 'top': topValue + 'px', 'left': leftValue + 'px' }">
                <div [style.display]="displayGpxMenu? 'block' : 'none'" class="dropdown kebab-dropdown dropdown-sm" #dropdown>
                    <div class="dropdown-menu gpx-menu">
                        <button class="dlt-btn cursor-pointer circuit-btn" *ngFor="let item of availableTrackVersions"
                            (click)="downloadBusinessTourDetails(item)">
                            <span>{{item}}</span>
                            <span class="icon icon_download selected-gpx"></span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <ng-template #selctedCardSection>
            <div class="business-detail-main">
                <div class="overlay-image" *ngIf="selectedLocation?.media_details?.length; else noImageBlock">
                    <div class="img-section">
                        <figure>
                            <img [src]="selectedLocation?.media_details[0]?.thumb_url" alt="...">
                        </figure>
                        <button class="back_btn" type="button" (click)="hideSelectedCardDetails()"><span class="icon icon_back_arrow"></span><span>{{locationButtonNames.BACK | customTranslation: translationData }}</span></button>
                    </div>
                </div>
                <ng-template #noImageBlock>
                    <div>
                    <button class="back-btn-no-img" type="button" (click)="hideSelectedCardDetails()"><span class="icon icon_back_arrow"></span><span>{{locationButtonNames.BACK | customTranslation: translationData}}</span></button>
                    </div>
                </ng-template>
                <div class="view-details" *ngIf="viewSelectedCardDetails">
                    <div class="gradient-section">
                        <div class="panel-row">
                            <ng-container *ngIf="selectedLocation.index === 'start'; else destinationBlock">
                                <span class="icon icon_start_flag" style="font-size: 1.5em;"></span><p class="loc-title" title="{{selectedLocation?.title}}"><span>| {{(selectedLocation?.title ? selectedLocation?.title: selectedLocation?.name) | shortenText: 19}}</span></p>
                            </ng-container>
                            <ng-template #destinationBlock>
                                <ng-container *ngIf="selectedLocation.index === 'destination' else wayPointBlock">
                                    <span class="icon icon_dest_flag" style="font-size: 1.5em;"></span><p class="loc-title" title="{{selectedLocation?.title}}"><span>| {{(selectedLocation?.title ? selectedLocation?.title: selectedLocation?.name) | shortenText: 19}}</span></p>
                                </ng-container>
                            </ng-template>
                            <ng-template #wayPointBlock>
                                <p class="loc-title" title="{{selectedLocation?.title ? selectedLocation?.title: selectedLocation?.name}}"><span>{{selectedLocation.index + 1}}</span><span> | {{(selectedLocation?.title ? selectedLocation?.title: selectedLocation?.name) | shortenText: 20}}</span></p>
                            </ng-template>
                        </div>
                        <span class="panel-row">
                            <small>{{selectedLocation?.name}}</small>
                        </span>
                        <p class="loc-details">
                            {{selectedLocation?.description ? selectedLocation?.description: ''}}
                        </p>
                    </div>
                </div>
            </div>
        </ng-template>
    </div>
</ng-container>
<!-------------------------------Code for Post tour creation end here------------------------------------------>
<div>
    <app-trackupload #trackUploadComponent (closeModal)="closeModal($event)" [page]="'emotiontourCreate'" [isShowModal]="showModal"
        [heading]="uploadHeading"></app-trackupload>
</div>
<div *ngIf="isAudioUploadVisible && tourInfoPostPublish">
<app-audio-upload (hideAudioUpload)="showAudioUploadDialog(false)" [tourGuid]="tourInfoPostPublish.guid"></app-audio-upload>
</div>

<div *ngIf="showCopyrightDialogBoxWithSavedInfo.showModal">
    <app-image-copyright-overlay [asset_type]="asset_type" [guid_trail]="tourInfoPostPublish?.guid"></app-image-copyright-overlay>
</div>