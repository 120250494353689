<div class="progress-cont">
  <div class="progress-line">
    <div class="progress" [style.width]="progress + '%'"></div>
  </div>
  <h1 *ngIf="progress < 100; else elseBlock">
    {{ "BP_CM_UPLOADING_FILE_LBL" | customTranslation : translationData }}
    {{ progress }}%...
  </h1>
  <ng-template #elseBlock>
    <h1>{{ messageToDisplay | customTranslation : translationData }}</h1>
  </ng-template>
</div>
